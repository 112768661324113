import React from "react";

function CheckmarkIcon() {
	return (
		<svg className="checkmark-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 27">
			<path
				fill="var(--color, #fff)"
				d="M5.994 8.47c-.148 0-.393.088-.5.199L.688 13.518c-.216.215-.216.48 0 .693l7.554 7.091 4.906 4.85c.11.107.252.196.4.196.11 0 .215-.114.301-.197l4.906-4.75 15.52-15.34a.561.561 0 000-.79L29.37.42c-.218-.212-.486-.215-.701 0l-15.12 14.943L6.396 8.67c-.108-.108-.252-.2-.4-.2z"
			></path>
		</svg>
	);
}

export default CheckmarkIcon;