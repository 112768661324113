import React from "react";

function ArrowIcon() {
	return (
		<svg className="arrow-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="60" height="76" viewBox="0 0 60 76">
			<g filter="url(#arrow-icon-a)">
				<path
					fill="#fff"
					d="M28.866 70.366l25.52-30.144c1.414-1.67.226-4.228-1.962-4.228H37.078V3.287A3.288 3.288 0 0033.788 0H21.21a3.289 3.289 0 00-3.29 3.287v32.707H2.575c-2.188 0-3.375 2.559-1.962 4.228l25.521 30.144a1.789 1.789 0 002.732 0z"
				></path>
			</g>
			<defs>
				<filter
					id="arrow-icon-a"
					width="60.001"
					height="76"
					x="-0.001"
					y="0"
					colorInterpolationFilters="sRGB"
					filterUnits="userSpaceOnUse"
				>
					<feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
					<feColorMatrix
						in="SourceAlpha"
						result="hardAlpha"
						values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
					></feColorMatrix>
					<feOffset dx="5" dy="5"></feOffset>
					<feComposite in2="hardAlpha" operator="out"></feComposite>
					<feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"></feColorMatrix>
					<feBlend
						in2="BackgroundImageFix"
						result="effect1_dropShadow_802_632"
					></feBlend>
					<feBlend
						in="SourceGraphic"
						in2="effect1_dropShadow_802_632"
						result="shape"
					></feBlend>
				</filter>
			</defs>
		</svg>
	);
}

export default ArrowIcon;
