import React from "react";
import { NavLink } from "react-router-dom";
import { CDN_BASE, ROUTES } from "../../Constants";
import ChevronDown from "../ui/ChevronDown";
import MicrosoftLogo from "../icons/MicrosoftLogo";
import FremantleLogo from "../icons/FremantleLogo";
import NVIDIALogo from "../icons/NVIDIALogo";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import XRLogo from "../icons/XRLogo";
import CinnafilmLogo from "../icons/CinnafilmLogo";
import MediaKindIcon from "../icons/MediaKindIcon";
import SymphonyAIIcon from "../icons/SymphonyAIIcon";

export default function Partners() {
	const { text, raw } = useTexts();
	
	return (
		<CSSTransition in={true} appear={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className="sub-page partners">
				<div className="header">
					<NavLink to={ROUTES.ROOT + ROUTES.MENU} className="back"><ChevronDown /><span {...raw(text?.partners?.back)} /></NavLink>
				</div>
				<div className="sections">
					<div className="section">
						<div className="logo">
							<MicrosoftLogo />
						</div>
						<div className="description" {...raw(text?.partners?.microsoft?.description)} />
						<a href={text?.partners?.microsoft?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.microsoft?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<FremantleLogo />
						</div>
						<div className="description" {...raw(text?.partners?.fremantle?.description)} />
						<a href={text?.partners?.fremantle?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.fremantle?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<NVIDIALogo />
						</div>
						<div className="description" {...raw(text?.partners?.nvidia?.description)} />
						<a href={text?.partners?.nvidia?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.nvidia?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<XRLogo />
						</div>
						<div className="description" {...raw(text?.partners?.aliceandsmith?.description)} />
						<a href={text?.partners?.aliceandsmith?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.aliceandsmith?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<img src={CDN_BASE + 'PFocus-Logo.png'} alt="" />
						</div>
						<div className="description" {...raw(text?.partners?.primefocustechnologies?.description)} />
						<a href={text?.partners?.primefocustechnologies?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.primefocustechnologies?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<CinnafilmLogo />
						</div>
						<div className="description" {...raw(text?.partners?.cinnafilm?.description)} />
						<a href={text?.partners?.cinnafilm?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.cinnafilm?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<MediaKindIcon />
						</div>
						<div className="description" {...raw(text?.partners?.mediakind?.description)} />
						<a href={text?.partners?.mediakind?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.mediakind?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<img src={CDN_BASE + 'MS-Advertising_logo_horiz_c-gray_rgb.png'} alt="" />
						</div>
						<div className="description" {...raw(text?.partners?.microsoftadvertising?.description)} />
						<a href={text?.partners?.microsoftadvertising?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.microsoftadvertising?.linkLabel)} /><ChevronDown /></a>
					</div>
					<div className="section">
						<div className="logo">
							<SymphonyAIIcon />
						</div>
						<div className="description" {...raw(text?.partners?.symphonyai?.description)} />
						<a href={text?.partners?.symphonyai?.link} target="_blank" className="contact-us" rel="noreferrer"><span {...raw(text?.partners?.symphonyai?.linkLabel)} /><ChevronDown /></a>
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}