import React, { ReactNode, useEffect, useRef } from 'react';
import ChevronDown from './ChevronDown';

type Props = {
	isOpened: boolean,
	toggleCollapse: () => void,
	heading: string,
	children: ReactNode,
	className?: string,
	headingClassName?: string,
	childrenClassName?: string,
};

export default function Collapsible({ isOpened, toggleCollapse = () => {}, heading, children, className = '', headingClassName = '', childrenClassName = '' }: Props) {
	const childrenRef = useRef<HTMLDivElement>();

	useEffect(() => {
		childrenRef.current.style.maxHeight = isOpened ? `${childrenRef.current.scrollHeight}px` : '0px';
	}, [isOpened]);

	return <div className={`collapsible ${className}`}>
		<div className={`heading ${headingClassName}`} onClick={toggleCollapse}>{heading} <ChevronDown className={isOpened ? 'opened' : ''} /></div>
		<div className={`children ${childrenClassName} ${isOpened ? '' : 'collapsed'}`} ref={childrenRef}>{children}</div>
	</div>;
}