import React from "react";

function NVIDIALogo() {
	return (
		<svg className="nvidia-logo" xmlns="http://www.w3.org/2000/svg" fill="none" width="169" height="32" viewBox="0 0 169 32">
			<path
				fill="#000"
				d="M102.936 6.148v20.965h5.832V6.148h-5.832zm-46.66 0v20.965h5.99V10.72h4.73c1.575 0 2.521.316 3.31 1.104.945.946 1.26 2.68 1.26 5.517v9.616H77.4V15.448c0-8.354-5.36-9.458-10.404-9.458l-10.72.158zm56.118 0v20.965h9.616c5.202 0 6.778-.788 8.67-2.68 1.261-1.26 2.049-4.256 2.049-7.566 0-2.995-.63-5.517-1.892-7.251-2.206-2.995-5.359-3.468-10.088-3.468h-8.355zm5.833 4.571h2.522c3.625 0 6.148 1.577 6.148 5.99 0 4.414-2.365 5.99-6.148 5.99h-2.522V10.72zM94.266 6.148l-4.887 16.71-4.729-16.71h-6.463l6.779 20.965h8.512l6.778-20.965h-5.99zm41.301 20.965h5.99V6.148h-5.99v20.965zm16.551-20.965l-8.197 20.965h5.833l1.261-3.783h9.773l1.261 3.626h6.306L160 5.99l-7.882.158zm3.783 3.783l3.626 9.774h-7.251l3.625-9.774zM17.97 9.616V6.778h.789c7.881-.315 13.083 6.779 13.083 6.779s-5.517 7.724-11.507 7.724c-.788 0-1.576-.158-2.365-.316v-8.827c3.153.315 3.626 1.734 5.518 4.729l4.098-3.468s-2.995-3.94-8.04-3.94c-.472 0-.945.157-1.576.157zm0-9.616v4.256h.789c11.034-.315 18.128 8.985 18.128 8.985s-8.197 9.931-16.71 9.931c-.788 0-1.418 0-2.207-.157v2.68c.631 0 1.262.157 1.892.157 8.04 0 13.714-4.098 19.39-8.827.945.788 4.728 2.522 5.517 3.31-5.36 4.414-17.813 8.04-24.75 8.04-.63 0-1.26 0-1.89-.158V32h30.265V0H17.97zm0 20.808v2.207c-7.408-1.261-9.458-8.985-9.458-8.985s3.468-3.941 9.458-4.572v2.522c-3.152-.315-5.517 2.522-5.517 2.522s1.419 4.887 5.517 6.306zM4.887 13.872S9.3 7.409 17.97 6.778V4.414C8.355 5.202 0 13.399 0 13.399s4.73 13.714 17.97 14.975v-2.522c-9.773-1.26-13.083-11.98-13.083-11.98z"
			></path>
		</svg>
	);
}

export default NVIDIALogo;