import React from "react";
import { CSSTransition } from "react-transition-group";
import { CDN_BASE } from "../Constants";
import Button from "./ui/Button";
import { useAppDispatch, useAppSelector } from "../redux/config/store";
import { setShowHomeScreenPrompt } from "../redux/game";

const isIOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g);

export default function InstallHomeScreenModal() {
	const dispatch = useAppDispatch();
	const showHomeScreenPrompt = useAppSelector(state => state.game.showHomeScreenPrompt)

	function onClick() {
		dispatch(setShowHomeScreenPrompt(false));
	}

	return <CSSTransition in={showHomeScreenPrompt} timeout={{ enter: 1200, appear: 1200, exit: 0 }} appear mountOnEnter unmountOnExit>
		<div className="install-home-screen-modal">
			<img src={CDN_BASE + '512.png'} width="128" height="128" alt="" />
			<div className="title">ONE LAST THING</div>
			<div className="text">
				<p>Save Beat the BUZZR® to your home screen in 3 simple steps:</p>
				<ol>
					<li>{"Open Beat the BUZZR® in your browser"}</li>
					{isIOS && <li>{"Tap the ‘Share’ icon (a box with an up arrow) at the bottom of the Safari browser"}</li>}
					{!isIOS && <li>{"Tap the three vertical dots in the top right corner of the screen"}</li>}
					<li>{"Select ‘Add to Home Screen’"}</li>
					<li>{"Tap ‘Add’ to finish adding Beat the BUZZR® to your home screen."}</li>
				</ol>
				<p>Warning: Deleting your browser cache will reset your progress</p>
			</div>
			<div className="btn-ctn">
				<Button className="accent" onClick={onClick} label="Start Playing" />
			</div>
			<div className="overlay" onClick={onClick} />
		</div>
	</CSSTransition>;
}