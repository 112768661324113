import { useEffect, useRef } from 'react';

export default function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef(callback);

	// Remember the latest callback if it changes.
	useEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	const id = useRef<number>();
	useEffect(() => {
		if (!delay) {
			window.clearInterval(id.current);
			return () => {};
		}

		id.current = window.setInterval(() => savedCallback.current(), delay);
		return () => {
			window.clearInterval(id.current);
		};
	}, [id, delay]);

	return () => window.clearInterval(id.current);
}
