import React, { useEffect, useState } from "react";
import { NavLink, useMatch } from "react-router-dom";
import { ROUTES } from "../Constants";
import ProfileIcon from "./icons/ProfileIcon";
import MissionIcon from "./icons/MissionIcon";
import TrophyIcon from "./icons/TrophyIcon";
import MenuIcon from "./icons/MenuIcon";
import Button from "./ui/Button";
import LightningIcon from "./icons/LightningIcon";
import LightningBGIcon from "./icons/LightningBGIcon";
import { CSSTransition } from "react-transition-group";
import useAnimationFrame from "../hooks/useAnimationFrame";
import { useAppDispatch, useAppSelector } from "../redux/config/store";
import { currentQuizSelector } from "../hooks/useQuizzes";
import useTexts from "../hooks/useTexts";
import useTutorialMode from "../hooks/useTutorialMode";
import ArrowIcon from "./icons/ArrowIcon";
import { setCountdown } from "../redux/game";

export default function Menu() {
	const [showBuzzr, setShowBuzzr] = useState(false);
	const countdown = useAppSelector((state) => state.game.countdown);
	const hasQuiz = Boolean(useAppSelector(currentQuizSelector));

	const { text, raw } = useTexts();

	const [tempHideBuzzr, setTempHideBuzzr] = useState(false);

	const {
		isTutorialMode,
	} = useTutorialMode();

	const isProfilePage =  useMatch(ROUTES.ROOT);
	const isMissionsPage =  useMatch(ROUTES.ROOT + ROUTES.MISSIONS);
	// const isRankingsPage =  useMatch(ROUTES.ROOT + ROUTES.RANKINGS);
	// const isMenuPage =  useMatch(ROUTES.ROOT + ROUTES.MENU);

	const profileButtonTutorialActive = isTutorialMode && isMissionsPage;
	const buzzrButtonTutorialActive = isTutorialMode && isProfilePage;

	useAnimationFrame(() => {
		if (countdown >= Date.now() && !showBuzzr) {
			setShowBuzzr(true);
		} else if (countdown < Date.now() && showBuzzr) {
			setShowBuzzr(false);
			setTempHideBuzzr(false);
		}
	});

	useEffect(() => {
		let timeout = null;
		if (tempHideBuzzr) {
			timeout = setTimeout(() => {
				setTempHideBuzzr(false);
			}, 10000);
		}

		return () => {
			clearTimeout(timeout);
			timeout = null;
		}
	}, [tempHideBuzzr]);

	return (
		<nav className={`menu ${isTutorialMode ? 'tutorial' : ''}`}>
			<div className="bar"></div>
			<div className="items">
				<NavLink className={`menu-item ${profileButtonTutorialActive ? 'tutorial-active' : ''}`} to={ROUTES.ROOT} end>
					<ArrowIcon />
					<span className="icon"><ProfileIcon /></span>
					<span {...raw(text?.menu?.profile)} />
				</NavLink>
				<NavLink className="menu-item" to={ROUTES.MISSIONS} end>
					<ArrowIcon />
					<span className="icon"><MissionIcon /></span>
					<span {...raw(text?.menu?.missions)} />
				</NavLink>
				<div className={`spacer ${buzzrButtonTutorialActive ? 'tutorial-active' : ''}`}>
					<ArrowIcon />
					<Button to={ROUTES.QUIZ} icon={<LightningIcon />} label="" className={`round no-shadow no-offset ${hasQuiz || buzzrButtonTutorialActive ? 'accent' : ''}`} />
				</div>
				<NavLink className="menu-item" to={ROUTES.RANKINGS} end>
					<ArrowIcon />
					<span className="icon"><TrophyIcon /></span>
					<span {...raw(text?.menu?.leaderboard)} />
				</NavLink>
				<NavLink className="menu-item" to={ROUTES.MENU}>
					<ArrowIcon />
					<span className="icon"><MenuIcon /></span>
					<span {...raw(text?.menu?.menu)} />
				</NavLink>
			</div>

			<div className="menu-clip">
				<CSSTransition in={!tempHideBuzzr && !isTutorialMode && showBuzzr} onClick={() => setTempHideBuzzr(true)} timeout={1200} appear>
					<div className="overlay"></div>
				</CSSTransition>

				<CSSTransition in={!tempHideBuzzr && !isTutorialMode && showBuzzr} timeout={1200} appear>
					<div className="quiz-notification">
						<LightningBGIcon />
						<div className="content">
							<div className="title" {...raw(text?.menu?.buzzr?.title)} />
							<div className="text" {...raw(text?.menu?.buzzr?.text)} />
						</div>
					</div>
				</CSSTransition>
			</div>
		</nav>
	)
}
