import React from "react";

function FacebookIcon() {
	return (
		<svg className="facebook-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="21" height="40" viewBox="0 0 21 40">
			<path
				fill="#FFF2D9"
				d="M.645 21.304H5.03v18.05c0 .357.289.646.645.646h7.434a.645.645 0 00.646-.645V21.389h5.04a.645.645 0 00.64-.572l.766-6.645a.645.645 0 00-.64-.719h-5.806V9.287c0-1.255.676-1.892 2.01-1.892h3.796a.645.645 0 00.645-.645V.65a.645.645 0 00-.645-.645h-5.232a5.127 5.127 0 00-.24-.005c-.907 0-4.063.178-6.555 2.471-2.761 2.541-2.378 5.583-2.286 6.11v4.872H.645a.645.645 0 00-.645.645v6.56c0 .357.289.646.645.646z"
			></path>
		</svg>
	);
}

export default FacebookIcon;
