import { useCallback, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { IPlayerStatistics } from '../redux/statistics';
import { getLeaderboard } from '../redux/leaderboard';
import usePlayer from './usePlayer';
import useGlobalVariables from './useGlobalVariables';
import { getGlobalVariables } from '../redux/globalVariables';

const LEADERBOARD_LENGTH = 20;

export default function useLeaderboard(statisticName: keyof IPlayerStatistics, forceUpdate = false) {
	let leaderboard = useAppSelector(s => s.leaderboard[statisticName]);
	const completeLeaderboard = leaderboard;
	const {
		leaderboardUrl,
		isLoaded: isGVLoaded,
	} = useGlobalVariables();

	leaderboard = leaderboard?.filter?.(x => x.StatValue > 0 && x.Profile.DisplayName)?.slice(0, LEADERBOARD_LENGTH);

	const {
		playerId,
	} = usePlayer();

	const dispatch = useAppDispatch();

	const fetchLeaderboard = useCallback(() => {
		if (isGVLoaded && leaderboardUrl) {
			console.log('fetchLeaderboard', leaderboardUrl);
			dispatch(getLeaderboard({ StatisticName: 'xp', url: leaderboardUrl }));
		}
	}, [dispatch, isGVLoaded, leaderboardUrl]);

	useEffect(() => {
		if (playerId && (!leaderboard || forceUpdate) && isGVLoaded && leaderboardUrl) {
			fetchLeaderboard();
		}
	}, [statisticName, playerId, dispatch, leaderboard, forceUpdate, fetchLeaderboard, isGVLoaded, leaderboardUrl]);

	return {
		updateLeaderboard: () => dispatch(getGlobalVariables()).then(fetchLeaderboard),
		leaderboard: leaderboard || [],
		completeLeaderboard,
	};
}
