import React, { useState } from "react";
import { createPortal } from "react-dom";
import LightningBGIcon from "../../icons/LightningBGIcon";
import useAnimationFrame from "../../../hooks/useAnimationFrame";
import { useAppSelector } from "../../../redux/config/store";
import useTexts from "../../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";

export default function NoQuiz() {
	const { text, raw } = useTexts();
	const [showBuzzr, setShowBuzzr] = useState(false);
	const countdown = useAppSelector((state) => state.game.countdown) + 1000;

	useAnimationFrame(() => {
		if (countdown >= Date.now() && !showBuzzr) {
			setShowBuzzr(true);
		} else if (countdown + 5000 < Date.now() && showBuzzr) {
			setShowBuzzr(false);
		}
	});

	return (
		<CSSTransition in={true} timeout={1200} appear>
			<div className="page no-quiz">
				<div className="content">
					{showBuzzr && <div className="title" {...raw(text?.noQuiz?.buzzr)} />}
					{!showBuzzr && <>
						<div className="title" {...raw(text?.noQuiz?.noBuzzr)} />
						<div className="text" {...raw(text?.noQuiz?.comeBack)} />
					</>}
				</div>

				{createPortal(<>
					<CSSTransition in={true} timeout={1200} appear>
						<div className={`quiz-bg theme-beige`}>
							<LightningBGIcon className="l-1" />
							<LightningBGIcon className="l-2" />
							<LightningBGIcon className="l-3" />
						</div>
					</CSSTransition>
				</>, document.body)}
			</div>
		</CSSTransition>
	);
}