import React from "react";

function LockIcon() {
	return (
		<svg className="lock-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 27 40">
			<path
				fill="#fff"
				d="M26.667 17.778H0V40h26.667V17.778zm-2.223-2.222H20V11.11c0-3.667-3-6.667-6.667-6.667-3.666 0-6.666 3-6.666 6.667v4.445H2.222V11.11C2.222 4.978 7.2 0 13.333 0c6.134 0 11.111 4.978 11.111 11.111v4.445z"
			></path>
		</svg>
	);
}

export default LockIcon;
