import React, { useCallback } from "react";
import LockIcon from "../../icons/LockIcon";
import CheckmarkIcon from "../../icons/CheckmarkIcon";
import Button from "../../ui/Button";
import useBadges from "../../../hooks/useBadges";
import usePlayer from "../../../hooks/usePlayer";
import { useAppDispatch } from "../../../redux/config/store";
import { updateAvatarUrl, updateLocalAvatarUrl } from "../../../redux/playfab";
import { CDN_BASE } from "../../../Constants";
import { CSSTransition } from "react-transition-group";

const defaultBadges = [
	CDN_BASE + 'default_badge_1.jpg',
	CDN_BASE + 'default_badge_2.jpg',
	CDN_BASE + 'default_badge_3.jpg',
	CDN_BASE + 'default_badge_4.jpg',
	CDN_BASE + 'default_badge_5.jpg',
	CDN_BASE + 'default_badge_6.jpg',
]

function Badge({ badge, owned, active, onClick }: { badge:IXRInventoryItemParsedData, owned: boolean, active: boolean, onClick: (badge:IXRInventoryItemParsedData) => void }) {	
	const onClickBadge = useCallback(() => {
		if (owned && !active) {
			onClick(badge);
		}
	}, [active, badge, onClick, owned]);

	return (
		<div className={`badge ${owned ? 'owned' : ''} ${active ? 'active' : ''}`} onClick={onClickBadge}>
			<div className="move">
				{active && <Button className="no-pointer round" icon={<CheckmarkIcon />} label="" />}
				{!owned && <LockIcon />}
				{badge.playfab.ItemImageUrl && <img src={badge.playfab.ItemImageUrl} alt={badge.playfab.DisplayName} />}
			</div>
		</div>
	)
}

export default function BadgesTab() {
	const {
		badges,
		catalog,
	} = useBadges();

	const dispatch = useAppDispatch();

	const {
		AvatarUrl,
	} = usePlayer();

	const debounceRef = React.useRef<number | null>(null);

	const onClick = useCallback((badge:IXRInventoryItemParsedData) => {
		if (debounceRef.current) {
			clearTimeout(debounceRef.current);
		}

		dispatch(updateLocalAvatarUrl(badge.playfab.ItemImageUrl));
		debounceRef.current = window.setTimeout(() => {
			dispatch(updateAvatarUrl(badge.playfab.ItemImageUrl));
		}, 1000);
	}, [dispatch]);

	const defaultElements = defaultBadges.map((url, i) => {
		const active = AvatarUrl === url;
		const badge = {
			playfab: {
				ItemId: `default_${i}`,
				DisplayName: `Default Badge ${i + 1}`,
				ItemImageUrl: url,
			}
		} as IXRInventoryItemParsedData;
		return <Badge key={url} badge={badge} owned={true} active={active} onClick={onClick} />;
	});

	const elements = catalog.map((badge) => {
		const owned = Boolean(badges.find((b) => b.playfab.ItemId === badge.playfab.ItemId));
		const active = AvatarUrl === badge.playfab.ItemImageUrl;
		return <Badge key={badge.playfab.ItemId} badge={badge} owned={owned} active={active} onClick={onClick} />;
	});
	
	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className="tab badges">
				<div className="box sub-box">
					<div className="badges">
						{defaultElements}
						{elements}
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}