import React, { memo } from 'react';
import LightningBGIcon from '../icons/LightningBGIcon';
import BuzzrIcon from '../icons/BuzzrIcon';
import FacebookIcon from '../icons/social/FacebookIcon';
import XIcon from '../icons/social/XIcon';
import LinkedInIcon from '../icons/social/LinkedInIcon';
import { CDN_BASE, ROUTES } from '../../Constants';
import { NavLink } from 'react-router-dom';
import ChevronDown from '../ui/ChevronDown';
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import useNativeShare from '../../hooks/useNativeShare';
import MicrosoftLogo from '../icons/MicrosoftLogo';
import FremantleLogo from '../icons/FremantleLogo';
import NVIDIALogo from '../icons/NVIDIALogo';
import useTexts from '../../hooks/useTexts';
import { CSSTransition } from 'react-transition-group';
import { useLogout } from '../../hooks/useLogout';
import Button from '../ui/Button';
import usePlayer from '../../hooks/usePlayer';
import useGlobalVariables from '../../hooks/useGlobalVariables';

// Show next show's image
const showImages = {
	'tttt': 'family-feud-thumbnail.jpg',
	'ff': 'sms-thumbnail.jpg',
	'sms': 'tttt-thumbnail.jpg',
}

function MenuPage() {
	const canShare = navigator.share;
	const url = window.location.origin;
	const { text, raw } = useTexts();

	const { playerId } = usePlayer();
	
	const {
		share: onShare,
		onCloseShare,
	} = useNativeShare(url);

	const {
		logout,
	} = useLogout();

	const {
		currentShow
	} = useGlobalVariables();

	function onClickResetPlayer() {
		if (window.confirm('Are you sure you want to reset your player?\n\nThis action is irreversible and will delete all your progress.')) {
			logout();
		}
	}

	return (
		<CSSTransition in={true} appear={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className="page menu-page">
				<LightningBGIcon />

				<div className="header">
					<BuzzrIcon />
					<div className="share-experience" {...raw(text?.menuPage?.shareExperience)} />
					<div className="share-icons">
						{canShare && <>
							<div onClick={onShare}><FacebookIcon /></div>
							<div onClick={onShare}><XIcon /></div>
							<div onClick={onShare}><LinkedInIcon /></div>
						</>}
						{!canShare && <>
							<FacebookShareButton url={url} onShareWindowClose={() => onCloseShare('facebook')}>
								<FacebookIcon />
							</FacebookShareButton>
							<TwitterShareButton url={url} onShareWindowClose={() => onCloseShare('twitter')}>
								<XIcon />
							</TwitterShareButton>
							<LinkedinShareButton url={url} onShareWindowClose={() => onCloseShare('linkedin')}>
								<LinkedInIcon />
							</LinkedinShareButton>
						</>}
					</div>
				</div>
				<div className="body">
					<div className="up-next">
						<div className="title" {...raw(text?.menuPage?.upNext)} />
						<div className="show-thumbnail">
							<img src={CDN_BASE + (showImages?.[currentShow] || showImages.tttt)} width="635" height="258" alt="" />
						</div>
					</div>
					<div className="links">
						<NavLink to={ROUTES.ROOT + ROUTES.MENU + '/' + ROUTES.PARTNERS} className="link"><span {...raw(text?.menuPage?.partners)} /> <ChevronDown /></NavLink>
						<NavLink to={ROUTES.ROOT + ROUTES.MENU + '/' + ROUTES.LANG} className="link"><span {...raw(text?.menuPage?.language)} /> <ChevronDown /></NavLink>
						<NavLink to={ROUTES.ROOT + ROUTES.MENU + '/' + ROUTES.TERMS_CONDITIONS} className="link"><span {...raw(text?.menuPage?.termsConditions)} /> <ChevronDown /></NavLink>
					</div>
				</div>
				<div className="footer">
					<div className="partners">
						<a href="https://microsoft.com" rel="noopener noreferrer" target="_blank"><MicrosoftLogo /></a>
						<a href="https://fremantle.com" rel="noopener noreferrer" target="_blank"><FremantleLogo /></a>
						<a href="https://nvidia.com" rel="noopener noreferrer" target="_blank"><NVIDIALogo /></a>
					</div>

					<div className="reset-player-btn">
						<Button className='' onClick={onClickResetPlayer} label="Reset Player" />
					</div>

					<div className="playfab-id">PlayFab ID: {playerId}</div>
				</div>
			</div>
		</CSSTransition>
	);
}

export default memo(MenuPage);
