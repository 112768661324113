import { useAppDispatch } from "../redux/config/store";
import { writePlayerEvent } from "../redux/playfab";

export default function useNativeShare(url: string) {
	const dispatch = useAppDispatch();
	
	function onCloseShare(type:string) {
		dispatch(writePlayerEvent({
			name: 'player_shared_app',
			body: {
				share: type,
			},
		}));
	}

	return {
		canShare: Boolean(navigator.share),
		share: () => {
			navigator.share({
				url,
			}).then(() => {
				onCloseShare('native share');
			});
		},
		onCloseShare,
	}
}