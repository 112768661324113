import React from "react";
import CloverIcon from "../../icons/CloverIcon";
import LightningBGIcon from "../../icons/LightningBGIcon";
import Button from "../../ui/Button";
import XpBar from "../../ui/XPBar";
import useXP from "../../../hooks/useXP";
import useStats from "../../../hooks/useStats";
import Lights from "../../ui/Lights";
import useTexts from "../../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import useTutorialMode from "../../../hooks/useTutorialMode";
import LightningIcon from "../../icons/LightningIcon";
import useLeaderboardAroundPlayer from "../../../hooks/useLeaderboardAroundPlayer";

export default function ProgressionTab() {
	const {
		level,
		currentLevelXp,
		nextLevelXp,
		xp,
	} = useXP();

	const { text, raw } = useTexts();

	const {
		triviaCompleted,
		fastestAnswer,
		boothsVisited,
	} = useStats();

	const {
		leaderBoardAroundPlayer,
	} = useLeaderboardAroundPlayer();

	const { isTutorialMode } = useTutorialMode();
	
	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className="tab progress">
				<div className="title" {...raw(text?.profile?.progression?.title)} />
				<div className="content">
					<div className="progression">
						<LightningBGIcon />
						<div className="chances">
							<Button icon={<CloverIcon />} label="" className="round" />
							<div className="qty">{level}x</div>
						</div>
						<div className="progression-box box accent sub-box">
							<Lights />
							<div className="box sub-box accent xp-bar-container">
								<XpBar xp={xp} level={level} currentLevelXp={currentLevelXp} nextLevelXp={nextLevelXp} />
							</div>
							<div className={`objective ${level >= 3 ? 'achieved' : ''}`}>
								<div className="checkbox box sub-box">
									<LightningIcon />
								</div>
								<div className="label" {...raw(text?.profile?.progression?.objective)} />
							</div>
						</div>
						{!isTutorialMode && <div className="stats">
							<div className="stat">
								<div className="value">{triviaCompleted}</div>
								<div className="label" {...raw(text?.profile?.progression?.triviaCompleted)}/>
							</div>
							<div className="stat">
								<div className="value">{fastestAnswer > 0 ? Math.round(fastestAnswer / 100) / 10 : '-'}</div>
								<div className="label" {...raw(text?.profile?.progression?.fastestAnswer)}/>
							</div>
							<div className="stat">
								<div className="value">{(leaderBoardAroundPlayer?.[0]?.Position || 998) + 1}</div>
								<div className="label" {...raw(text?.profile?.progression?.leaderboardPosition)}/>
							</div>
							<div className="stat">
								<div className="value">{boothsVisited}</div>
								<div className="label" {...raw(text?.profile?.progression?.boothsVisited)}/>
							</div>
						</div>}
						{isTutorialMode && <div className="tutorial-text">
							<svg xmlns="http://www.w3.org/2000/svg" fill="none" className="arrow" viewBox="0 0 47 129">
								<path
									fill="#fff"
									d="M8.707.293a1 1 0 00-1.414 0L.929 6.657A1 1 0 002.343 8.07L8 2.414l5.657 5.657a1 1 0 101.414-1.414L8.707.293zM9 128V1H7v127h2z"
								></path>
								<path stroke="#fff" strokeWidth="2" d="M7 128h40"></path>
							</svg>
							<div className="text" {...raw(text?.profile?.tutorial)} />
						</div>}
					</div>
				</div>
			</div>
		</CSSTransition>
	)
}