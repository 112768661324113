import React from "react";

function ScanIcon() {
	return (
		<svg className="scan-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 106 88">
			<g fill="#fff" clipPath="url(#a)">
				<path d="M94.73 26.801a2.099 2.099 0 002.095-2.103V2.103A2.099 2.099 0 0094.731 0H72.225a2.1 2.1 0 00-2.095 2.103 2.1 2.1 0 002.095 2.102h20.411v20.493a2.1 2.1 0 002.095 2.103zM72.225 88h22.506a2.099 2.099 0 002.094-2.103V63.3a2.099 2.099 0 00-2.094-2.103 2.099 2.099 0 00-2.095 2.103v20.493h-20.41a2.1 2.1 0 00-2.096 2.103 2.1 2.1 0 002.095 2.103V88zM11.27 88h22.505a2.1 2.1 0 002.094-2.103 2.098 2.098 0 00-2.094-2.103H13.364V63.3a2.099 2.099 0 00-2.095-2.102A2.1 2.1 0 009.174 63.3v22.597a2.1 2.1 0 002.095 2.104V88zm0-61.199a2.099 2.099 0 002.094-2.103V4.206h20.41a2.099 2.099 0 002.095-2.103A2.099 2.099 0 0033.775 0H11.27a2.1 2.1 0 00-2.095 2.103v22.595A2.1 2.1 0 0011.27 26.8zM2.534 47.32h100.933c1.399 0 2.533-1.486 2.533-3.321 0-1.835-1.134-3.321-2.533-3.321H2.534c-1.4 0-2.533 1.486-2.533 3.321 0 1.835 1.134 3.321 2.533 3.321z"></path>
			</g>
			<defs>
				<clipPath id="a">
					<path fill="#fff" d="M106 0H0v88h106z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default ScanIcon;
