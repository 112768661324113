import React from "react";
import { CDN_BASE } from "../../../Constants";
import useTexts from "../../../hooks/useTexts";
import Button from "../../ui/Button";
import { useAppDispatch } from "../../../redux/config/store";
import { setShowHomeScreenPrompt, setTutorialMode } from "../../../redux/game";
import { CSSTransition } from "react-transition-group";

export default function QuizTutorial() {
	const { raw, text } = useTexts();
	const dispatch = useAppDispatch();

	function onClickButton() {
		dispatch(setTutorialMode(false));
		dispatch(setShowHomeScreenPrompt(true));
	}

	return <CSSTransition in={true} timeout={900} appear>
		<div className="quiz-tutorial">
			<img src={CDN_BASE + 'quiz-tutorial.jpg'} alt="" />
			<div className="title" {...raw(text?.quizTutorial?.title)} />
			<div className="text" {...raw(text?.quizTutorial?.text)} />
			<Button className="accent" onClick={onClickButton} label={text?.quizTutorial?.btnLabel} />
		</div>
	</CSSTransition>;
}
