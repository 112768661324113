import React, { memo } from 'react';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';
import { ROUTES } from './Constants';
import '../css/index.scss';
import usePlayFab from './hooks/usePlayFab';
import useRealtime from './hooks/useRealtime';
import Debug from './components/Debug';
import Login from './components/routes/Login';
import Notifications from './components/ui/Notifications';
import usePlayerStatistics from './hooks/usePlayerStatistics';
import FTUE from './components/routes/FTUE';
import Menu from './components/Menu';
import Profile from './components/routes/Profile';
import Missions from './components/routes/Missions';
import Rankings from './components/routes/Rankings';
import QuizPage from './components/routes/QuizPage';
import MenuPage from './components/routes/MenuPage';
import Partners from './components/routes/Partners';
import TermsConditions from './components/routes/TermsConditions';
import Lang from './components/routes/Lang';
import { useAppSelector } from './redux/config/store';
import InstallHomeScreenModal from './components/InstallHomeScreenModal';
import Preload from './Preload';

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

function App() {
	/*
	* Initialize PlayFab XR connection (once logged in, we fetch all the player data, you should)
	* only use this hook once, and only at the root of your app.
	*/
	const { playerId, isLoaded } = usePlayFab();
	const { hasLoaded, hasCompletedFTUE } = usePlayerStatistics();

	// Connect to realtime if the service is active in this app
	useRealtime();

	const isLogin = useMatch(ROUTES.ROOT + ROUTES.LOGIN);
	const isFTUE = useMatch(ROUTES.ROOT + ROUTES.FTUE);
	const showLogin = !playerId && !isLogin;
	const showFTUE = playerId && !hasCompletedFTUE && hasLoaded;
	const showMenu = !isFTUE && !isLogin;

	const lang = useAppSelector((state) => state.env.Lang);

	return (
		<div className={lang + (isSafari ? ' safari' : '')}>
			<Preload />
			{showLogin && <Navigate to={ROUTES.LOGIN} replace={true} />}
			{showFTUE && <Navigate to={ROUTES.FTUE} replace={true} />}
			<Debug />
			<Routes>
				<Route path={ROUTES.ROOT}>
					<Route index element={isLoaded && hasLoaded && <Profile />} />
					<Route path={ROUTES.LOGIN} element={<Login />} />
					<Route path={ROUTES.FTUE} element={<FTUE />} />
					<Route path={ROUTES.MENU}>
						<Route index element={<MenuPage />} />
						<Route path={ROUTES.PARTNERS} element={<Partners />} />
						<Route path={ROUTES.LANG} element={<Lang />} />
						<Route path={ROUTES.TERMS_CONDITIONS} element={<TermsConditions />} />
					</Route>
					<Route path={ROUTES.MISSIONS} element={<Missions />} />
					<Route path={ROUTES.RANKINGS} element={<Rankings />} />
					<Route path={ROUTES.QUIZ} element={<QuizPage />} />
				</Route>
			</Routes>
			{showMenu && <Menu />}
			{/* <SkipTutorial /> */}
			<InstallHomeScreenModal />
			<Notifications />
		</div>
	);
}

export default memo(App);
