import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../Constants";
import ChevronDown from "../ui/ChevronDown";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";

export default function TermsConditions() {
	const { text, raw } = useTexts();
	
	return (
		<CSSTransition in={true} appear={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className="sub-page terms-conditions">
				<div className="header">
					<NavLink to={ROUTES.ROOT + ROUTES.MENU} className="back"><ChevronDown /><span {...raw(text?.termsConditions?.back)} /></NavLink>
				</div>
				<div className="section">
					<div className="title">TERMS & CONDITIONS</div>
					<div className="text">
						<h2>Eligibility Criteria</h2>
						<ul>
							<li>The contest is open to legal residents of the United States who are at least 18 years old at the time of entry. </li>
							<li>Contestant must be attending NAB 2024 in Las Vegas, Nevada.</li>
							<li>Employees of Microsoft Corporation, its affiliates, subsidiaries, advertising and promotion agencies, and suppliers (collectively the “Employees”), immediate family members, and/or those living in the same household of Employees are not eligible to participate in the contest. </li>
						</ul>

						<h2>Participation Mechanism</h2>
						<ul>
							<li>Participants can enter the contest by completing missions in the Beat the BUZZR demo at the NAB show 2024.</li>
							<li>Participants are limited to one entry per person.</li>
						</ul>

						<h2>Selection of Winners</h2>
						<ul>
							<li>Winners will be selected at random from the pool of eligible entries.</li>
							<li>The top 5 scorers in the Beat the BUZZR demo will each receive a complimentary Xbox Game Pass.</li>
							<li>Scoring will be determined by the points accrued during the completion of missions within the Beat the BUZZR demo. The top 5 scorers will be identified based on the highest scores achieved during the contest period.</li>
							<li>Potential winners will be contacted via the contact information provided at the time of entry to verify eligibility and confirm the prize.</li>
						</ul>

						<h2>Prize Details</h2>
						<ul>
							<li>The main prize consists of one (1) Xbox console. No substitution or transfer of the main prize is permitted except by Microsoft, who reserves the right to substitute the prize with one of equal or greater value.</li>
							<li>In addition to the main prize, the top 5 scorers will each receive an Xbox Game Pass.</li>
							<li>Prizes are subject to availability, and Microsoft reserves the right to replace any prize with an alternative product if necessary.</li>
						</ul>

						<h2>Notification and Claiming of Prizes</h2>
						<ul>
							<li>The winners of the Xbox console and the Xbox Game Passes will be announced at the conclusion of the NAB Show 2024 and will also be notified via the contact information provided at the time of entry.</li>
							<li>Winners must claim their prize within 30 days of being notified for the Xbox console and within 15 days of notification for the Xbox Game Pass.</li>
							<li>If a winner cannot be contacted or fails to claim the prize within this time, the prize may be forfeited and an alternate winner selected.</li>
						</ul>


						<h2>Timing and Duration</h2>
						<ul>
							<li>The contest begins and ends with the NAB Show 2024 (April 1317, 2024). </li>
						</ul>

						<h2>Data Privacy</h2>
						<ul>
							<li>Personal data collected from participants will only be used in connection with the contest and will not be disclosed to any third party except for the purpose of administering the prize.</li>
							<li>Participant data will be retained for a period necessary to administer the contest and prize distribution and then securely destroyed.</li>
						</ul>

						<h2>Legal Compliance</h2>
						<ul>
							<li>The contest is subject to federal, state, and local laws and regulations and is void where prohibited by law. </li>
							<li>Microsoft complies with all applicable laws regarding contests and prize giveaways. </li>
						</ul>

						<h2>Publicity</h2>
						<ul>
							<li>By participating in the contest, participants consent to the use of their names, likenesses, and entries for promotional purposes in any media without additional payment or consideration, unless prohibited by law.</li>
						</ul>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}