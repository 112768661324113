import React from "react";

function CloseIcon() {
	return (
		<svg className="close-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
			<path
				fill="#fff"
				d="M40 32.725L27.275 20 40 7.275 32.725 0 20 12.725 7.275 0 0 7.275 12.725 20 0 32.725 7.275 40 20 27.275 32.725 40 40 32.725z"
			></path>
		</svg>
	);
}

export default CloseIcon;
