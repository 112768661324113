import { createSlice } from '@reduxjs/toolkit';
import defaultTexts from '../texts';
import { getGlobalVariables } from './globalVariables';

export type Texts = typeof defaultTexts;

const initialState = {
	translations: {
		'en': defaultTexts,
	} as Record<string, Texts>,
};

const text = createSlice({
	name: 'text',
	reducers: {
		setTranslations: (state, action) => {
			state.translations = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(getGlobalVariables.actions.fulfilled, (state, action) => {
			action.payload.data.GlobalVariables.filter(gv => gv.dataKey.startsWith('translation_')).forEach(gv => {
				const lang = gv.dataKey.split('_')[1];
				state.translations[lang] = gv.dataVal;
			});
		});
	},
	initialState,
});

export default text;

export const { setTranslations } = text.actions;
