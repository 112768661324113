import React, { useCallback, useEffect, useState } from "react";
import Lights from "../ui/Lights";
import LightningBGIcon from "../icons/LightningBGIcon";
import TrophyIcon from "../icons/TrophyIcon";
import RefreshIcon from "../icons/RefreshIcon";
import useLeaderboard from "../../hooks/useLeaderboard";
import usePlayer from "../../hooks/usePlayer";
import { CDN_BASE } from "../../Constants";
import useLeaderboardAroundPlayer from "../../hooks/useLeaderboardAroundPlayer";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import useInterval from "../../hooks/useInterval";

export default function Rankings() {
	const { text, raw } = useTexts();
	
	const {
		leaderboard,
		updateLeaderboard,
	} = useLeaderboard('xp');

	const [once, setOnce] = useState(false);

	const {
		playerId
	} = usePlayer();

	const {
		leaderBoardAroundPlayer,
		updateLeaderboardAroundPlayer,
	} = useLeaderboardAroundPlayer('xp');

	const playerEntry = leaderBoardAroundPlayer?.find(p => p.PlayFabId === playerId);
	
	const defaultAvatar = CDN_BASE + 'profile_default.png';

	const filler = Array.from({ length: 10 - leaderboard.length }, (_, i) => i);

	const update = useCallback(() => {
		updateLeaderboard();
		updateLeaderboardAroundPlayer();
	}, [updateLeaderboard, updateLeaderboardAroundPlayer]);

	useEffect(() => {
		if (!once) {
			update();
			setOnce(true);
		}
	}, [once, update]);

	useInterval(() => {
		update();
	}, 60000);

	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className="page rankings">
				<LightningBGIcon />
				<div className="box sub-box ctn">
					{/* <div className="refresh-btn" onClick={update}>
						<div className="move box">
							<div className="refresh">
								<RefreshIcon />
							</div>
						</div>
					</div> */}
					<div className="top box sub-box">
						<TrophyIcon />
						<div className="title" {...raw(text?.rankings?.leaderboard)} />
						<Lights />
					</div>
					<div className="leaderboard-description" {...raw(text?.rankings?.description)} />
					<div className="leaderboard">
						{leaderboard.map((entry, i) => (
							<div className={`entry ${entry.PlayFabId === playerId ? 'player' : ''}`} key={entry.PlayFabId}>
								<div className="rank">{entry.Position + 1}</div>
								<div className={`data box sub-box ${entry.PlayFabId === playerId ? 'accent' : ''}`}>
									<div className="avatar">
										<img src={entry.Profile.AvatarUrl || defaultAvatar} width="100" height="100" alt="" />
									</div>
									<div className="content">
										<div className="name">{entry.DisplayName}</div>
									</div>
									<div className="xp">{entry.StatValue} <span {...raw(text?.rankings?.xp)} /></div>
								</div>
							</div>
						))}
						{filler.map((_, i) => (
							<div className="entry" key={i}>
								<div className="rank">&nbsp;</div>
								<div className="data box sub-box">
									<div className="avatar">
										<div className="empty-avatar"></div>
									</div>
									<div className="content">
										<div className="name"></div>
									</div>
									<div className="xp"></div>
								</div>
							</div>
						))}
						{
							leaderboard.find(p => p.PlayFabId === playerId) === undefined && playerEntry && (
								<div className={`entry playerEntry`} key={playerEntry.PlayFabId}>
									<div className="rank">{playerEntry.Position + 1}</div>
									<div className={`data box sub-box accent`}>
										<div className="avatar">
											<img src={playerEntry.Profile.AvatarUrl || defaultAvatar} width="100" height="100" alt="" />
										</div>
										<div className="content">
											<div className="name">{playerEntry.DisplayName}</div>
										</div>
										<div className="xp">{playerEntry.StatValue} <span {...raw(text?.rankings?.xp)} /></div>
									</div>
								</div>
							)
						}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}