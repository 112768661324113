import { PayloadAction, createSlice } from "@reduxjs/toolkit";

function getLSCollapsed() {
	const collapsedMissionSections = localStorage.getItem('collapsedMissionSections');
	if (collapsedMissionSections) {
		return JSON.parse(collapsedMissionSections);
	}
	return [];
};

function setLSCollapsed(lang: string) {
	localStorage.setItem('collapsedMissionSections', lang);
}

const game = createSlice({
	name: 'game',
	initialState: {
		countdown: 0,
		countdownText: '',
		factoid: null as {
			text: string,
			image: string,
			sponsor: string,
			expiration: number,
		},
		tutorialMode: Boolean(localStorage.getItem('forceTutorial')) || false,
		showHomeScreenPrompt: false,
		collapsedMissionSections: getLSCollapsed() as string[],
	},
	reducers: {
		setCountdown: (state, action:PayloadAction<number>) => {
			state.countdown = action.payload;
		},
		setCountdownText: (state, action:PayloadAction<string>) => {
			state.countdownText = action.payload;
		},
		setFactoid: (state, action:PayloadAction<{
			text: string,
			image: string,
			sponsor: string,
			expiration: number,
		}>) => {
			state.factoid = action.payload;
		},
		toggleCollapsedMissionSection: (state, action:PayloadAction<string>) => {
			const section = action.payload;
			if (state.collapsedMissionSections.includes(section)) {
				state.collapsedMissionSections = state.collapsedMissionSections.filter(s => s !== section);
			} else {
				state.collapsedMissionSections.push(section);
			}
			setLSCollapsed(JSON.stringify(state.collapsedMissionSections));
		},
		setTutorialMode: (state, action:PayloadAction<boolean>) => {
			state.tutorialMode = action.payload;
		},
		setShowHomeScreenPrompt: (state, action:PayloadAction<boolean>) => {
			state.showHomeScreenPrompt = action.payload;
		}
	},
});

export default game;

export const { setCountdown, setCountdownText, setFactoid, toggleCollapsedMissionSection, setTutorialMode, setShowHomeScreenPrompt } = game.actions;