import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../Constants";
import ChevronDown from "../ui/ChevronDown";
import { useAppDispatch, useAppSelector } from "../../redux/config/store";
import { setLang } from "../../redux/env";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";

export default function Lang() {
	const { text, raw } = useTexts();

	const langs = [
		{ code: 'en', name: text?.lang?.english },
		{ code: 'de', name: text?.lang?.german },
		{ code: 'fr', name: text?.lang?.french },
		{ code: 'es', name: text?.lang?.spanish },
	];
	
	const currentLang = useAppSelector(state => state.env.Lang);
	const dispatch = useAppDispatch();

	function onChangeLang(e: React.ChangeEvent<HTMLInputElement>) {
		dispatch(setLang(e.target.value));
	}

	return (
		<CSSTransition in={true} appear={true} timeout={1500} mountOnEnter unmountOnExit>
			<div className="sub-page lang">
				<div className="header">
					<NavLink to={ROUTES.ROOT + ROUTES.MENU} className="back"><ChevronDown /><span {...raw(text?.lang?.back)} /></NavLink>
				</div>
				<div className="section">
					<div className="cols">
						<div className="col">
							<div className="title" {...raw(text?.lang?.audio)} />
							{langs.map((lang, i) => {
								return <label key={i} className={`lang-input ${lang.code === 'en' ? '' : 'disabled'}`}>
									<div className="inside">
										<input readOnly type="radio" name="video-lang" id="video-lang" value={lang.code} checked={'en' === lang.code} />
										<CheckmarkIcon />
										<span>{lang.name}</span>
									</div>
								</label>;
							})}
						</div>
						<div className="col">
							<div className="title" {...raw(text?.lang?.application)} />
							{langs.map((lang, i) => {
								return <label key={i} className="lang-input">
									<div className="inside">
										<input type="radio" onChange={onChangeLang} name="app-lang" id="app-lang" value={lang.code} checked={currentLang === lang.code} />
										<CheckmarkIcon />
										<span>{lang.name}</span>
									</div>
								</label>;
							})}
						</div>
					</div>
				</div>
			</div>
		</CSSTransition>
	);
}