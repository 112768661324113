import React from "react";
import { CDN_BASE } from "./Constants";

export default function Preload() {
	return (
		<>
			<link rel="preload" href={CDN_BASE + 'transition.mp4'} as="video" />
			<link rel="preload" href={CDN_BASE + 'transition.webm'} as="video" />
		</>
	);
}