import React from "react";

function MediaKindIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 466.275 118.668"
    >
      <defs>
        <path id="media-a" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="media-b">
        <use overflow="visible" xlinkHref="#media-a"></use>
      </clipPath>
      <path
        fill="#009FE3"
        d="M281.526 75.178V57.68c0-1.254 1.517-1.883 2.403-.997l8.754 8.746c.551.552.551 1.442.002 1.992l-8.754 8.754c-.888.888-2.405.256-2.405-.997"
        clipPath="url(#media-b)"
      ></path>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <defs>
        <path id="v" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="w">
        <use overflow="visible" xlinkHref="#v"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M111.709 76.275c-3.781 0-7.365-.584-10.652-1.732-3.312-1.164-6.245-2.907-8.729-5.189-2.493-2.287-4.482-5.121-5.911-8.424-1.428-3.304-2.153-7.141-2.153-11.402 0-4.07.708-7.836 2.102-11.195 1.392-3.366 3.359-6.298 5.848-8.716 2.483-2.417 5.447-4.313 8.813-5.64 3.363-1.326 7.091-1.997 11.082-1.997 3.521 0 6.687.465 9.406 1.385 2.728.921 5.069 2.172 6.961 3.719 1.903 1.56 3.365 3.409 4.342 5.499a15.715 15.715 0 011.468 6.702c0 2.419-.459 4.655-1.363 6.644-.929 2.03-2.569 3.785-4.88 5.219-2.26 1.407-5.325 2.511-9.108 3.286-3.734.767-8.54 1.158-14.283 1.158a129.393 129.393 0 01-6.484-.15c.641 2.747 2.096 4.954 4.433 6.728 2.593 1.972 6.396 2.97 11.301 2.97 1.669 0 3.227-.13 4.629-.386a26.228 26.228 0 003.813-.963c1.16-.397 2.108-.777 2.896-1.159.823-.395 1.43-.688 1.823-.889l.7-.354 4.886 9.304-.624.392c-.543.341-1.391.813-2.537 1.422-1.179.622-2.646 1.209-4.36 1.747-1.717.539-3.724 1.018-5.968 1.416-2.248.401-4.754.605-7.451.605M97.537 46.078a98.31 98.31 0 003.534.066h4.177c3.578 0 6.502-.228 8.69-.68 2.16-.443 3.853-1.014 5.03-1.698 1.104-.64 1.828-1.35 2.153-2.11a6.46 6.46 0 00.532-2.573c0-2.244-.869-3.877-2.656-4.994-1.887-1.173-4.27-1.767-7.085-1.767-2.325 0-4.388.409-6.133 1.217-1.749.815-3.233 1.904-4.41 3.235-1.188 1.342-2.122 2.914-2.784 4.677a17.274 17.274 0 00-1.048 4.627"
        clipPath="url(#w)"
      ></path>
      <defs>
        <path id="x" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="y">
        <use overflow="visible" xlinkHref="#x"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M167.962 76.275c-3.999 0-7.73-.688-11.088-2.047-3.364-1.361-6.313-3.26-8.758-5.639-2.455-2.384-4.404-5.281-5.796-8.615-1.396-3.326-2.102-6.978-2.102-10.848 0-3.925.672-7.589 1.996-10.889 1.326-3.293 3.188-6.191 5.536-8.609a25.025 25.025 0 018.321-5.649c3.168-1.331 6.668-2.003 10.4-2.003 3.948 0 7.484.783 10.505 2.325 2.254 1.153 4.164 2.508 5.704 4.039V6.069c0-2.03.615-3.574 1.826-4.589C185.679.497 187.34 0 189.442 0c1.263 0 2.444.162 3.509.481 1.031.311 1.713.534 2.059.672l.501.199v45.387c0 4.91-.704 9.255-2.09 12.916-1.397 3.672-3.352 6.775-5.802 9.229-2.451 2.451-5.391 4.32-8.74 5.552-3.324 1.221-6.998 1.839-10.917 1.839m.199-42.562c-2.196 0-4.194.396-5.939 1.175a14.373 14.373 0 00-4.571 3.203c-1.28 1.342-2.274 2.973-2.96 4.846-.694 1.883-1.045 3.964-1.045 6.191 0 2.225.351 4.287 1.043 6.134.686 1.849 1.682 3.462 2.962 4.803a14.286 14.286 0 004.571 3.2c3.427 1.54 8.224 1.572 11.848.048 1.777-.746 3.309-1.789 4.557-3.099 1.247-1.312 2.244-2.93 2.962-4.807.727-1.888 1.094-4.001 1.094-6.279 0-2.145-.367-4.191-1.094-6.081-.718-1.879-1.721-3.534-2.976-4.92-1.25-1.37-2.782-2.459-4.553-3.237-1.784-.781-3.767-1.177-5.899-1.177"
        clipPath="url(#y)"
      ></path>
      <defs>
        <path id="z" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="A">
        <use overflow="visible" xlinkHref="#z"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M216.471 74.885H203.54V29.438c0-2.107.641-3.672 1.9-4.656 1.197-.937 2.867-1.414 4.965-1.414 1.264 0 2.445.164 3.51.486 1.021.306 1.707.532 2.05.666l.506.196v50.169z"
        clipPath="url(#A)"
      ></path>
      <defs>
        <path id="B" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="C">
        <use overflow="visible" xlinkHref="#B"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M250.659 76.275c-5.218 0-9.554-.461-12.89-1.369-3.369-.92-6.074-2.174-8.04-3.726-2.001-1.577-3.394-3.438-4.137-5.525a18.744 18.744 0 01-1.092-6.284c0-2.814.439-5.322 1.306-7.46.883-2.186 2.343-4.041 4.34-5.513 1.968-1.448 4.561-2.547 7.702-3.267 3.092-.708 6.897-1.065 11.316-1.065 2.494 0 4.925.084 7.221.25 1.727.129 3.123.247 4.195.355-.086-1.793-.435-3.311-1.043-4.526-.689-1.388-1.65-2.489-2.851-3.269-1.231-.796-2.738-1.358-4.481-1.671-1.807-.321-3.836-.486-6.024-.486-3.656 0-6.625.417-8.819 1.242-2.204.833-3.649 1.525-4.298 2.058l-.507.417-.505-.422c-.456-.377-1.035-1.039-1.771-2.024-.796-1.063-1.202-2.358-1.202-3.854 0-1.063.283-2.114.843-3.124.573-1.028 1.566-1.934 2.948-2.696 1.324-.732 3.111-1.314 5.31-1.727 2.174-.406 4.933-.61 8.201-.61 3.954 0 7.61.391 10.872 1.167 3.3.781 6.186 2.044 8.584 3.757 2.424 1.73 4.332 4.041 5.677 6.867 1.337 2.82 2.017 6.246 2.017 10.188v30.586l-.642.129c-.679.134-1.74.284-3.195.455a189.66 189.66 0 01-5.045.501c-1.931.163-4.094.315-6.49.447-2.384.132-4.906.199-7.5.199m-1.095-24.661c-4.332 0-7.497.642-9.406 1.907-1.835 1.212-2.727 3.091-2.727 5.748 0 1.081.195 2.081.581 2.966.364.845 1.019 1.581 1.941 2.188.977.64 2.338 1.136 4.048 1.478 1.755.351 3.995.528 6.656.528 3 0 5.409-.112 7.156-.341a79.494 79.494 0 002.886-.413V52.476c-.704-.122-1.721-.28-3.049-.471-1.773-.261-4.494-.391-8.086-.391"
        clipPath="url(#C)"
      ></path>
      <defs>
        <path id="D" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="E">
        <use overflow="visible" xlinkHref="#D"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M345.406 74.885h-12.931V29.438c0-2.107.641-3.672 1.9-4.656 1.196-.937 2.866-1.414 4.965-1.414 1.264 0 2.444.164 3.51.486 1.021.306 1.707.532 2.05.666l.506.196v50.169z"
        clipPath="url(#E)"
      ></path>
      <defs>
        <path id="F" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="G">
        <use overflow="visible" xlinkHref="#F"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M403.557 74.885H390.63V46.841c0-4.281-1.033-7.56-3.068-9.748-2.021-2.177-5.068-3.277-9.064-3.277-4.056 0-7.121 1.1-9.104 3.27-2.008 2.193-3.025 5.474-3.025 9.755v28.044H353.44V43.957c0-3.418.646-6.525 1.917-9.239 1.269-2.707 3.042-5.04 5.279-6.93 2.214-1.879 4.879-3.335 7.923-4.33 3.028-.982 6.374-1.479 9.939-1.479 3.573 0 6.934.497 9.988 1.479 3.075.991 5.756 2.448 7.979 4.33a19.756 19.756 0 015.233 6.938c1.233 2.706 1.859 5.811 1.859 9.231v30.928z"
        clipPath="url(#G)"
      ></path>
      <defs>
        <path id="H" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="I">
        <use overflow="visible" xlinkHref="#H"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M438.728 76.275c-3.999 0-7.729-.688-11.088-2.047-3.367-1.361-6.314-3.26-8.758-5.639-2.457-2.384-4.404-5.284-5.796-8.615-1.396-3.326-2.104-6.978-2.104-10.848 0-3.925.673-7.589 1.997-10.889 1.326-3.293 3.188-6.191 5.538-8.609a24.996 24.996 0 018.319-5.649c3.168-1.331 6.668-2.003 10.398-2.003 3.949 0 7.485.783 10.505 2.325 2.254 1.153 4.164 2.508 5.705 4.039V6.069c0-2.03.615-3.574 1.826-4.589C456.441.497 458.102 0 460.205 0c1.264 0 2.441.162 3.508.481 1.032.311 1.715.534 2.06.672l.501.199v45.387c0 4.91-.703 9.255-2.089 12.916-1.399 3.672-3.35 6.775-5.804 9.229-2.449 2.451-5.388 4.32-8.738 5.552-3.323 1.221-6.997 1.839-10.915 1.839m.199-42.562c-2.194 0-4.194.396-5.94 1.175a14.313 14.313 0 00-4.57 3.203c-1.279 1.342-2.275 2.975-2.959 4.846-.694 1.887-1.049 3.968-1.049 6.191s.354 4.285 1.043 6.134c.688 1.847 1.684 3.46 2.965 4.803a14.295 14.295 0 004.568 3.203c3.432 1.537 8.23 1.569 11.852.045 1.773-.746 3.307-1.789 4.557-3.099 1.247-1.314 2.246-2.932 2.96-4.807.729-1.888 1.096-4.001 1.096-6.279 0-2.145-.366-4.191-1.094-6.081-.718-1.875-1.719-3.532-2.976-4.922-1.25-1.368-2.782-2.457-4.555-3.235-1.78-.781-3.768-1.177-5.898-1.177"
        clipPath="url(#I)"
      ></path>
      <defs>
        <path id="J" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="K">
        <use overflow="visible" xlinkHref="#J"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M317.566 75.504a6.856 6.856 0 01-4.863-2.014l-29.125-29.125a6.879 6.879 0 010-9.729l29.125-29.125a6.88 6.88 0 119.728 9.731L298.174 39.5l24.257 24.26a6.878 6.878 0 01-4.865 11.744"
        clipPath="url(#K)"
      ></path>
      <defs>
        <path id="L" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="M">
        <use overflow="visible" xlinkHref="#L"></use>
      </clipPath>
      <path
        fill="#E40138"
        d="M287.201 3.475c1.924 0 3.549.662 4.873 1.987 1.324 1.328 1.988 2.951 1.988 4.874s-.664 3.548-1.988 4.875c-1.324 1.326-2.949 1.988-4.873 1.988-1.923 0-3.548-.662-4.873-1.988-1.325-1.327-1.987-2.952-1.987-4.875s.662-3.546 1.987-4.874c1.325-1.325 2.95-1.987 4.873-1.987"
        clipPath="url(#M)"
      ></path>
      <defs>
        <path id="N" d="M0 118.668V0h466.275v118.668"></path>
      </defs>
      <clipPath id="O">
        <use overflow="visible" xlinkHref="#N"></use>
      </clipPath>
      <path
        fill="#004F89"
        d="M71.989 4.036a6.878 6.878 0 00-7.495 1.492L32.967 37.055 20.675 24.761c-.008-.006-.016-.01-.021-.017l-8.916-8.917a6.882 6.882 0 00-9.727 0A6.858 6.858 0 000 20.74v48.658a6.878 6.878 0 1013.754 0V37.296l5.413 5.412c.006.007.013.009.019.015l8.921 8.92a6.88 6.88 0 009.725 0l24.65-24.652v42.407a6.878 6.878 0 1013.754 0V10.39a6.882 6.882 0 00-4.247-6.354"
        clipPath="url(#O)"
      ></path>
    </svg>
  );
}

export default MediaKindIcon;
