import { useAppSelector } from "../redux/config/store";
import { Texts } from "../redux/text";
import { raw } from "../utils/textUtils";
import defaultTexts from "../texts";

export default function useTexts() {
	const texts = useAppSelector((state) => state.text.translations);
	const lang = useAppSelector((state) => state.env.Lang);
	const text = texts[lang] || defaultTexts as Texts;
	
	return {
		text,
		raw
	};
}