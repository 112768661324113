import React, { useEffect, useRef, useState } from 'react';
import { useAppSelector, useAppDispatch } from '../../redux/config/store';
import { removeNotification } from '../../redux/notifications';
import { raw } from '../../utils/textUtils';
import CheckmarkIcon from '../icons/CheckmarkIcon';

// eslint-disable-next-line no-unused-vars
type CloseNotificationFn = (notification: IXRRealtimeNotification) => void;

export const NotificationListPositions = {
	TOP_LEFT: 'top-left',
	TOP_RIGHT: 'top-right',
	TOP: 'top',
	BOTTOM_LEFT: 'bottom-left',
	BOTTOM_RIGHT: 'bottom-right',
} as const;
export type NotificationListPosition = typeof NotificationListPositions[keyof typeof NotificationListPositions];

function Notification({ notification, closeNotification }: { notification: IXRRealtimeNotification, closeNotification: CloseNotificationFn }) {
	const {
		Title: title,
		Message: message,
		Icon: icon,
	} = notification;

	const [isAnimatingOut, setIsAnimatingOut] = useState(false);

	useEffect(() => {
		let animTimeout = null;
		const int = setTimeout(() => {
			setIsAnimatingOut(true);
			animTimeout = setTimeout(() => {
				closeNotification(notification);
			}, 1000);
		}, 5000);

		return () => {
			clearTimeout(int);
			clearTimeout(animTimeout);
		};
	}, [closeNotification, notification]);

	const onClick = () => {
		setIsAnimatingOut(true);
		setTimeout(() => {
			closeNotification(notification);
		}, 1000);
	};

	return (
		<div className={`notification ${isAnimatingOut ? 'out' : ''} ${icon !== 'XP' ? 'no-icon' : ''}`} onClick={onClick}>
			<div className="content-wrapper">
				<div className="content">
					<div className="title" {...raw(title)} />
					<div className="description">
						<CheckmarkIcon />
						<span {...raw(message)} />
					</div>
				</div>
				{icon === 'XP' && <div className="icon">XP</div>}
			</div>
		</div>
	);
}

export default function Notifications({ position = NotificationListPositions.TOP_RIGHT }: { position?: NotificationListPosition }) {
	const listRef = useRef<HTMLDivElement>(null);

	const notifications = useAppSelector((state) => state.notifications.list);
	const dispatch = useAppDispatch();

	const sortedNotifications = position.includes('bottom')
		? [...notifications].reverse()
		: [...notifications];

		
	useEffect(() => {
		const el = listRef.current;
		const isTopPosition = ([
			NotificationListPositions.TOP_LEFT,
			NotificationListPositions.TOP_RIGHT,
		] as NotificationListPosition[]).includes(position);
		if (isTopPosition) {
			el?.scrollTo(0, el.scrollHeight);
		} else {
			el?.scrollTo(0, 0);
		}
	}, [notifications, position]);

	function closeNotification(notification: IXRRealtimeNotification) {
		dispatch(removeNotification(notification.NotificationId));
	}

	return (
		<>
			<div className={`notifications-list ${position}`} ref={listRef}>
				{sortedNotifications.map(n => <Notification key={n.NotificationId} notification={n} closeNotification={closeNotification} />)}
			</div>
			{/* {createPortal(<Button className='debug-notification' label="debug-notification" onClick={() => {
				dispatch(addNotification(notificationGenerator({
					title: 'YOU HAVE GAINED<br />1000 XP +  BADGE',
					icon: 'XP',
					message: `Scan your pass QR Code`,
				})));
			}} />, document.body)} */}
		</>
	);
}