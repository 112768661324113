import React, { useEffect, useState } from "react";
import BuzzrIcon from "../icons/BuzzrIcon";
import LightningBGIcon from "../icons/LightningBGIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants";
import { useAppDispatch } from "../../redux/config/store";
import { executeCloudScript, setNeedRefresh } from "../../redux/playfab";
import LoadingSpinner from "../icons/LoadingSpinner";
import { createPortal } from "react-dom";
import CheckmarkIcon from "../icons/CheckmarkIcon";
import Button from "../ui/Button";
import useTexts from "../../hooks/useTexts";
import FremantleLogo from "../icons/FremantleLogo";
import MicrosoftLogo from "../icons/MicrosoftLogo";
import NVIDIALogo from "../icons/NVIDIALogo";
import { CSSTransition } from "react-transition-group";
import { setTutorialMode } from "../../redux/game";
import LightningIcon from "../icons/LightningIcon";
import usePlayerStatistics from "../../hooks/usePlayerStatistics";

export default function FTUE() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const { text, raw } = useTexts();

	const { hasCompletedFTUE } = usePlayerStatistics();
	const [profileLoadingVisible, setProfileLoadingVisible] = useState(false);
	const [profileDoneVisible, setProfileDoneVisible] = useState(false);

	useEffect(() => {
		if (hasCompletedFTUE && !profileLoadingVisible && !profileDoneVisible) {
			navigate(ROUTES.ROOT + ROUTES.MISSIONS, { replace: true });
		}
	}, [hasCompletedFTUE, navigate, profileDoneVisible, profileLoadingVisible]);

	function onClickStart() {
		navigate(ROUTES.ROOT + ROUTES.MISSIONS, { replace: true });
	}

	function onScan() {
		dispatch(executeCloudScript({
			functionName: 'QrScan',
			data: {}
		})).then((resp) => {
			setProfileLoadingVisible(false);
			setProfileDoneVisible(true);
			dispatch(setNeedRefresh(true));
			dispatch(setTutorialMode(true));
		});
		setProfileLoadingVisible(true);
	}

	return (
		<CSSTransition in={true} timeout={1200} appear mountOnEnter unmountOnExit>
			<div className="ftue">
				<div className="bg">
					<LightningBGIcon />
					<div className="content">
						<BuzzrIcon />
						<div className="text" {...raw(text?.ftue?.description)} />
					</div>
				</div>
				<div className="user-profile-scan">
					<div className="center">
						<div className="text" {...raw(text?.ftue?.createProfile)} />
						<div className="qr-scan box" onClick={onScan}>
							<div className="qr-code">
								<LightningIcon />
							</div>
							<div className="scan-text" {...raw(text?.ftue?.scanQR)} />
						</div>
					</div>
					<div className="footer">
						<div className="partners">
							<a href="https://microsoft.com" rel="noopener noreferrer" target="_blank"><MicrosoftLogo /></a>
							<a href="https://fremantle.com" rel="noopener noreferrer" target="_blank"><FremantleLogo /></a>
							<a href="https://nvidia.com" rel="noopener noreferrer" target="_blank"><NVIDIALogo /></a>
						</div>
					</div>
				</div>
				{profileLoadingVisible && createPortal(
					<CSSTransition in={true} timeout={1200} appear>
						<div className="ftue-profile-loading">
							<LoadingSpinner />
						</div>
					</CSSTransition>,
					document.body,
				)}
				{profileDoneVisible && createPortal(
					<CSSTransition in={true} timeout={1200} appear>
						<div className="ftue-profile-done">
							<Button label="" icon={<CheckmarkIcon />} className="check round accent no-offset" />
							<div className="title" {...raw(text?.ftue?.readyTitle)} />
							<div className="text" {...raw(text?.ftue?.readyText)} />
							<Button label={text?.ftue?.startNow} onClick={onClickStart} className="start accent" />
						</div>
					</CSSTransition>,
					document.body,
				)}
			</div>
		</CSSTransition>
	);
}