import React from 'react';
import { currentQuizSelector } from "../../hooks/useQuizzes";
import Quiz from "./quiz/Quiz";
import NoQuiz from './quiz/NoQuiz';
import { useAppSelector } from '../../redux/config/store';
import useTutorialMode from '../../hooks/useTutorialMode';
import QuizTutorial from './quiz/QuizTutorial';

export default function QuizPage() {
	const currentQuiz = useAppSelector(currentQuizSelector);

	const { isTutorialMode } = useTutorialMode();

	if (isTutorialMode) return <QuizTutorial />;
	if (currentQuiz) return <Quiz key={currentQuiz?.id} />
	return <NoQuiz />;
}