import React, { useEffect, useState } from "react";
import usePlayerStatistics from "../../../hooks/usePlayerStatistics";
import useLeaderboardAroundPlayer from "../../../hooks/useLeaderboardAroundPlayer";
import usePlayer from "../../../hooks/usePlayer";
import useTexts from "../../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";

export default function StatisticsTab() {
	const {
		boothsVisited,
		badgesUnlocked,
		fastestAnswer,
		triviaCompleted,
		missionsCompleted,
		pollsCompleted,
	} = usePlayerStatistics();

	const [update, setUpdate] = useState(true);
	const { playerId } = usePlayer();
	const { text, raw } = useTexts();

	const { leaderBoardAroundPlayer } = useLeaderboardAroundPlayer('xp', update);
	const playerEntry = leaderBoardAroundPlayer?.find(p => p.PlayFabId === playerId);

	useEffect(() => {
		setUpdate(false);
	}, []);

	const stats:{ name: string, value:number|'-', position: number }[] = [
		{ name: text?.profile?.statistics?.partnerBoothsVisited, value: boothsVisited, position: 0 },
		{ name: text?.profile?.statistics?.badgesUnlocked, value: badgesUnlocked, position: 0 },
		{ name: text?.profile?.statistics?.fastestTriviaAnswer, value: fastestAnswer > 0 ? Math.round(fastestAnswer / 100) / 10 : '-', position: 0 },
		{ name: text?.profile?.statistics?.triviaCompleted, value: triviaCompleted, position: 0 },
		{ name: text?.profile?.statistics?.pollsCompleted, value: pollsCompleted, position: 0 },
		{ name: text?.profile?.statistics?.missionsCompleted, value: missionsCompleted, position: 0 },
		{ name: text?.profile?.statistics?.leaderboardPosition, value: playerEntry?.Position + 1 ?? '-', position: 0 },
	];

	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className="tab statistics">
				<div className="box sub-box accent stats-ctn">
					{stats.map(stat => {
						let qtyClass = '';
						if (stat.value !== '-') {
							const v = stat.value.toString().replace('.', '');
							if (v.length >= 3) {
								qtyClass = 'high';
							} else if (v.length >= 2) {
								qtyClass = 'mid';
							}
						}
						return (<div className="stat box accent" key={stat.name}>
							<div className={`qty ${qtyClass}`}>
								{stat.value}
							</div>
							<div className="label">
								<div className="name" {...raw(stat.name)} />
							</div>
						</div>)
					})}
				</div>
			</div>
		</CSSTransition>
	)
}