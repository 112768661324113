/* eslint-disable no-console */
import { useCallback, useEffect, useRef } from 'react';
import { API_CONFIG, CDN_BASE } from '../Constants';
import { getSingleAdPlacement, updateLocalAd } from '../redux/ads';
import { addChatMessage } from '../redux/chat';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { getItemInventory } from '../redux/inventory';
import { getMissionInventory } from '../redux/missions';
import { addNotification, notificationGenerator } from '../redux/notifications';
import { updateLocalAvatarUrl, updateLocalVirtualCurrency, updateLocalDisplayName } from '../redux/playfab';
// import { setActivePrediction, setPredictionResult } from '../redux/predictions';
import { addRFIDTag } from '../redux/rfid';
import { updateLocalStatistic } from '../redux/statistics';
import { getStoreLoadout } from '../redux/xr_store';
import usePlayer from './usePlayer';
import { addLocalPoll, generatePollFromRealtime } from '../redux/polls';
import useRealtimeConnection from '../api/react/hooks/useRealtimeConnection';
import { ANY_EVENT } from '../api/Realtime';
import { setPlayDrop } from '../redux/env';
import { addQuizInstance } from '../redux/quizzes';
import { setCountdown } from '../redux/game';
import { Howl } from 'howler';
import useMissions from './useMissions';
import useBadges from './useBadges';
import useTexts from './useTexts';
import { setGlobalVariable } from '../redux/globalVariables';

function debugRealtime(msg:string) {
	const colors = (msg.match(/%c/g) || []).length;
	const d = new Date();
	const date = `%c @ ${d.getHours().toString().padStart(2, '0') + ':' + d.getMinutes().toString().padStart(2, '0') + ':' + d.getSeconds().toString().padStart(2, '0')}.${d.getMilliseconds()}`;
	switch (colors) {
		case 0:
			console.log(msg + date);
			break;
		case 1:
			console.log(msg + date, 'color: #ccc;', 'color: grey;');
			break;
		case 2:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: grey;');
			break;
		case 3:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: grey;');
			break;
		default:
		case 4:
			console.log(msg + date, 'color: #ccc;', 'color: #ffbca0;', 'color: teal;', 'color: yellow', 'color: grey;');
			break;
	}
}

export default function useRealtime() {
	const {
		versions,
		playerStatus,
	} = useAppSelector((state) => state.realtime);

	const missions = useMissions();
	const lang = useAppSelector((state) => state.env.Lang);
	const { text } = useTexts();
	const { catalog: badges } = useBadges();

	const bellSound = useRef(new Howl({ src: CDN_BASE + 'bell.mp3' }));

	const dispatch = useAppDispatch();

	const placements = useAppSelector((state) => state.ads);

	const {
		playerId,
		SessionTicket,
	} = usePlayer();

	const missionNotification = useCallback((missionId:string) => {
		const completedMission = missions.find((m) => m.itemId === missionId);
		if (completedMission) {
			const xpReward = completedMission.rewards.find(r => r.dataType === 'stat_change' && r.dataKey === 'xp');
			const badgeReward = completedMission.rewards.find(r => r.dataType === 'item_grant' && badges.find(b => b.itemId === r.dataVal));
			const displayName = completedMission.data?.localization?.[lang]?.displayName || completedMission.playfab.DisplayName;
			
			let title = text?.notifications?.mission?.replace?.('%XP%', (xpReward?.dataVal as string || '0') + text?.notifications?.xp)?.replace('%BADGE%', badgeReward ? text?.notifications?.badge : '');
			if (completedMission.data?.notificationOverride) {
				title = completedMission.data?.localization?.[lang]?.notificationOverride || completedMission.data.notificationOverride as string;
			}

			dispatch(addNotification(notificationGenerator({
				title,
				icon: 'XP',
				message: displayName,
			})));
		}
	}, [dispatch, lang, missions, text, badges]);

	const onMessage = useCallback((data) => {
		const eventName = data.EventName;

		let msg = `%crealtime %c${eventName}`;

		switch (eventName) {
			case 'player_rule_executed':
				msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;
				break;
			case 'player_triggered_action_executed_cloudscript':
				msg = `%crealtime %c${eventName} %c[${data.FunctionName}]`;
				break;
			case 'player_action_executed':
				msg = `%crealtime %c${eventName} %c[${data.ActionName}]`;
				break;
			case 'player_entered_segment':
			case 'player_left_segment':
				msg = `%crealtime %c${eventName} %c[${data.SegmentName}]`;
				break;
			case 'player_statistic_changed':
				dispatch(updateLocalStatistic({
					name: data.StatisticName,
					value: data.StatisticValue,
				}));
				msg = `%crealtime %c${eventName} %c[${data.StatisticName}: ${data.StatisticPreviousValue} > ${data.StatisticValue}]`;
				break;
			case 'app_statistic_changed':
				msg = `%crealtime %c${eventName} %c[${data.StatisticName}: ${data.StatisticPreviousValue} > ${data.StatisticValue}]`;
				break;
			case 'player_consumed_item':
			case 'player_inventory_item_added':
				dispatch(getItemInventory());
				msg = `%crealtime %c${eventName} %c[${data.DisplayName}]`;

				if (data.ItemId.includes('m-')) {
					dispatch(getMissionInventory());
				}
				break;
			case 'player_virtual_currency_balance_changed':
				dispatch(updateLocalVirtualCurrency({ currency: data.VirtualCurrencyName, amount: data.VirtualCurrencyBalance }));
				msg = `%crealtime %c${eventName} %c[${data.VirtualCurrencyName}: ${data.VirtualCurrencyPreviousBalance} > ${data.VirtualCurrencyBalance}]`;
				break;
			case 'player_displayname_changed':
				dispatch(updateLocalDisplayName(data.DisplayName));
				msg = `%crealtime %c${eventName} %c[${data.PreviousDisplayName} > ${data.DisplayName}]`;
				break;
			case 'player_changed_avatar':
				dispatch(updateLocalAvatarUrl(data.ImageUrl));
				break;
			case 'player_notification_pushed':
				dispatch(addNotification(data));
				break;
			case 'store':
			case 'player_store_cleared':
				dispatch(getStoreLoadout());
				break;
			case 'notifications':
				dispatch(addNotification(data));
				break;
			case 'player_mission_completed':
			case 'player_objective_progress':
			case 'player_objective_completed':
				dispatch(getMissionInventory());

				if (eventName === 'player_mission_completed') {
					missionNotification(data.MissionId);
				}
				
				msg = `%crealtime %c${eventName} %c[${data.MissionId} > ${data.ObjectiveId}]`;
				break;
			case 'xr_chat_message':
				dispatch(addChatMessage(data));
				break;
			case 'rfid_login_attempt':
				msg = `%crealtime %c${eventName} %c${data.rfid} %c@${data.scannerId}`;
				dispatch(addRFIDTag(data));
				break;
			case 'title_poll_instantiated':
			case 'player_poll_instantiated':
				console.log(data);
				msg = `%crealtime %c${eventName} %c${data.PollInstance} %c${data.PollQuestion}`;
				dispatch(addLocalPoll(generatePollFromRealtime(data)));
				break;
			case 'player_ad_triggered':
				msg = `%crealtime %c${eventName} %c${data}`;
				dispatch(updateLocalAd({
					ad: data.Ad,
					placementId: data.AdPlacementId,
				}));
				break;
			case 'player_ad_reset':
				const ids = Object.keys(placements);
				ids.forEach((placement) => {
					dispatch(getSingleAdPlacement(placement));
				});
				break;
			case 'player_trade_sent':
				const currencyMap = {
					WW: 'Water',
					FF: 'Food',
					TT: 'Tech',
				};
				dispatch(addNotification(notificationGenerator({
					title: '',
					icon: 'small',
					message: `<span class="user">${data.DisplayName}</span> gave <span class="streamer">KingMooran</span>: ${Object.entries(data.Currencies).map(([currency, amount]) => `<span class="currency"><span class="amount">${amount}</span> ${currencyMap[currency]}</span>`).join(', ')}`,
				})));
				console.log(data);
				break;
			case 'drop_initiated':
				dispatch(setPlayDrop(true));
				break;
			case 'match_survey_instantiated':
			case 'match_trivia_instantiated':
				dispatch(addQuizInstance({
					...data.QuizData,
					totalExpiration: data.QuizTotalExpiration,
				}));
			break;
			case 'title_current_show_changed':
				dispatch(setGlobalVariable({
					key: 'currentShow',
					value: data.currentShow,
				}));
			break;
			case 'title_countdown_start':
				dispatch(setCountdown(data.countdownTimestamp));
				bellSound.current.play();
				msg = `%crealtime %c${eventName} %c${data.countdownTimestamp}`;
				break;
		}

		debugRealtime(msg);
	}, [dispatch, missionNotification, placements]);

	const {
		addListener,
		removeListener,
	} = useRealtimeConnection(API_CONFIG.realtime.apiUrl, API_CONFIG.playfab.appId, playerId, SessionTicket);

	useEffect(() => {
		addListener(ANY_EVENT, onMessage);

		return () => {
			removeListener(ANY_EVENT, onMessage);
		};
	}, [addListener, onMessage, removeListener]);

	return {
		newsVersion: versions.news,
		playerStatus,
	};
}