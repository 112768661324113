import React from "react";

function MissionIcon() {
	return (
		<svg className="mission-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
			<path
				fill="var(--icon-color, #FFEBC6)"
				d="M0 24.285v5.71L19.978 40l20-10.005v-5.71l-20 9.984L0 24.285z"
			></path>
			<path
				fill="var(--icon-color, #FFEBC6)"
				d="M39.977 21.424l-18.564-9.276v-.884a5.29 5.29 0 00.806-.276 5.561 5.561 0 001.811-1.237 5.916 5.916 0 001.215-1.822 5.58 5.58 0 00.453-2.22 5.58 5.58 0 00-.453-2.22 5.737 5.737 0 00-1.215-1.81A5.894 5.894 0 0022.22.453 5.396 5.396 0 0019.999 0a5.58 5.58 0 00-2.22.453 5.756 5.756 0 00-1.822 1.226 5.587 5.587 0 00-1.226 1.81 5.58 5.58 0 00-.452 2.22c0 .785.154 1.524.453 2.22a5.757 5.757 0 001.225 1.822c.52.52 1.127.928 1.822 1.237a5 5 0 00.785.265v.906L.01 21.424 20 31.43l19.977-10.006zm-23.335 3.943a13.1 13.1 0 01-1.601-.453 9.29 9.29 0 01-1.712-.795 5.381 5.381 0 01-1.358-1.16 2.382 2.382 0 01-.553-1.535c0-.574.177-1.082.553-1.535a5.383 5.383 0 011.358-1.16 8.623 8.623 0 011.712-.794 13.08 13.08 0 011.601-.453 14.73 14.73 0 011.668-.265c.077-.011.154-.011.232-.022v4.218c0 .144.055.265.165.354.11.088.243.165.398.22.154.067.309.1.475.122.165.022.298.033.397.033.11 0 .243-.01.398-.033a1.69 1.69 0 00.463-.121c.155-.056.288-.133.387-.221.11-.089.155-.2.155-.354v-4.196c.022 0 .044 0 .055.01.585.067 1.204.155 1.833.288.64.133 1.27.31 1.889.519a7.46 7.46 0 011.69.795c.496.32.905.696 1.203 1.127.31.42.464.916.464 1.457s-.155 1.038-.464 1.458a4.37 4.37 0 01-1.204 1.127c-.497.32-1.06.585-1.69.795-.629.21-1.258.386-1.888.519a16.51 16.51 0 01-1.833.287c-.585.055-1.082.088-1.48.088-.563 0-1.115-.022-1.667-.077a11.187 11.187 0 01-1.646-.243z"
			></path>
		</svg>
	);
}

export default MissionIcon;
