import React, { CSSProperties, memo, useCallback, useEffect, useRef, useState } from 'react';
import useQuizzes from '../../../hooks/useQuizzes';
import Countdown from '../../ui/Countdown';
import Button from '../../ui/Button';
import LightningBGIcon from '../../icons/LightningBGIcon';
import { createPortal } from 'react-dom';
import CheckmarkIcon from '../../icons/CheckmarkIcon';
import CloseIcon from '../../ui/CloseIcon';
import { useAppDispatch, useAppSelector } from '../../../redux/config/store';
import useTexts from '../../../hooks/useTexts';
import { CSSTransition } from "react-transition-group";
import { addNotification, notificationGenerator } from '../../../redux/notifications';
import { CDN_BASE } from '../../../Constants';

const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

const THEMES = {
	ORANGE: 'orange',
	YELLOW: 'yellow',
	BEIGE: 'beige',
} as const;
type Theme = typeof THEMES[keyof typeof THEMES];

function Quiz() {
	let { currentQuiz, answerQuiz, statistics, previousAnswer, closeQuiz } = useQuizzes();

	const question = currentQuiz && currentQuiz?.questions?.[0];
	const [selectedAnswer, setSelectedAnswer] = useState<string>(null);
	const theme: Theme = currentQuiz?.customData?.theme || THEMES.ORANGE;
	const [isEnded, setIsEnded] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { raw, text } = useTexts();

	const [rewards, setRewards] = useState(null);

	const dispatch = useAppDispatch();

	let boxBg = '';
	if (theme === THEMES.BEIGE) {
		boxBg = 'accent secondary';
	}

	const onSubmit = useCallback(() => {
		setIsSubmitting(true);
		answerQuiz(selectedAnswer).then((resp) => {
			setIsSubmitting(false);
			console.log(resp);
			const xpReceived = resp?.payload?.data?.FunctionResult?.data?.Rewards?.Statistics?.xp;
			console.log(resp?.payload?.data?.FunctionResult?.data?.Rewards?.Statistics?.xp);
			setRewards(xpReceived);

			if (currentQuiz.type === 'survey') {
				dispatch(addNotification(notificationGenerator({
					title: text.quiz.notificationTitle.replace('%XP%', xpReceived),
					message: text.quiz.notificationTextSurvey,
					icon: 'XP'
				})))
			}
		})
	}, [answerQuiz, currentQuiz.type, dispatch, selectedAnswer, text.quiz.notificationTextSurvey, text.quiz.notificationTitle]);

	const classes = ['page', 'quiz', 'theme-' + theme, currentQuiz?.type];
	const videoRef = useRef<HTMLVideoElement>(null);

	const isAnswered = isEnded || previousAnswer;
	const showRightWrong = isAnswered && currentQuiz.type === 'trivia' && isEnded;

	useEffect(() => {
		if (showRightWrong && rewards) {
			console.log('rewards', rewards);
			dispatch(addNotification(notificationGenerator({
				title: text.quiz.notificationTitle.replace('%XP%', rewards),
				message: text.quiz.notificationTextTrivia,
				icon: 'XP'
			})))
		}
	}, [dispatch, rewards, showRightWrong, text.quiz.notificationTextTrivia, text.quiz.notificationTitle]);

	const lang = useAppSelector((state) => state.env.Lang);
	const localization = JSON.parse(currentQuiz?.customData?.localization || '{}')?.[lang] || {};

	if (isAnswered) {
		classes.push('answered');
	}

	if (statistics.result.length > 0) {
		classes.push('show-stats');
	}

	const onRunningChange = useCallback((isRunning:boolean) => {
		if (!isRunning && !previousAnswer) {
			answerQuiz('timeout');
			setSelectedAnswer(null);
		}
		if (!isRunning) {
			setIsEnded(true);
		}
	}, [answerQuiz, previousAnswer]);

	useEffect(() => {
		if (!currentQuiz) return;

		const time = (currentQuiz.totalExpiration * 1000) - Date.now();
		const tm = setTimeout(() => {
			closeQuiz();
		}, time);
		return () => clearTimeout(tm);
	}, [closeQuiz, currentQuiz]);

	return currentQuiz && (
		<CSSTransition in={true} timeout={1200} appear>
			<div className={classes.join(' ')}>
				<video ref={videoRef} className="in-transition" autoPlay muted playsInline disablePictureInPicture preload="preload">
					<source src={CDN_BASE + 'transition.mp4'} type='video/mp4; codecs="hvc1"' />
					<source src={CDN_BASE + 'transition.webm'} type="video/webm" />
				</video>
				<div className="page-content">
					{currentQuiz?.customData?.thumbnail && <div className="thumbnail">
						<img width="680" height="280" src={currentQuiz.customData.thumbnail} alt="" />
					</div>}
					<div className="question-ctn">
						<Countdown onRunningChange={onRunningChange} reverse targetTime={currentQuiz.voteExpiration} startTime={currentQuiz.timestamp} />
						<div className="content">
							{currentQuiz?.customData?.sponsorImage && <div className="sponsor"><span {...raw(text?.quiz?.questionBrought)} /><img width="120" height="25" src={currentQuiz?.customData?.sponsorImage} alt="" /></div>}
							<div className="question">{localization?.question || question.question}</div>
						</div>
					</div>
					<div className={`choices box sub-box ${boxBg}`}>
						{question.choices.map((choice, index) => {
							const percent = statistics?.result?.find((r) => r.code === choice.code)?.percent || 0;

							const choiceClasses = ['choice', 'box'];

							if (selectedAnswer === choice.code || previousAnswer === choice.code) {
								choiceClasses.push('accent');
							}

							if (showRightWrong && currentQuiz?.answers?.[0]?.correctAnswer && choice.code === currentQuiz?.answers?.[0]?.correctAnswer) {
								choiceClasses.push('good');
							}

							if (showRightWrong && currentQuiz?.answers?.[0]?.correctAnswer && choice.code === selectedAnswer && selectedAnswer !== currentQuiz?.answers?.[0]?.correctAnswer) {
								choiceClasses.push('wrong');
							}

							return <div
								onClick={() => setSelectedAnswer(choice.code)}
								className={choiceClasses.join(' ')}
								key={choice.code}
							>
								<div className="letter box">{letters[index]}</div>
								<div className="label">{localization.choices?.[index] || choice.label}</div>
								<div className="icon">
									<CheckmarkIcon />
									<CloseIcon />
								</div>
								<div className="statistics">
									<div className="bar box sub-box" style={{ '--percent': percent.toString() } as CSSProperties} />
									<div className="percent">{percent || 0}%</div>
								</div>
							</div>
						})}
					</div>
					<div className="btn-ctn">
						{!isAnswered && <Button label={text?.quiz?.submit} onClick={onSubmit} disabled={!selectedAnswer || isSubmitting} />}
						{/* {isAnswered && <Button label={text?.quiz?.close} onClick={closeQuiz} />} */}
					</div>
				</div>
				{createPortal(<>
					<CSSTransition in={true} timeout={1200} appear>
						<div className={`quiz-bg ${currentQuiz?.customData?.theme ? 'theme-' + currentQuiz?.customData?.theme : 'theme-beige'}`} style={{ '--timing': '0s', '--delay': '0.6s' } as CSSProperties}>
							<LightningBGIcon className="l-1" />
							<LightningBGIcon className="l-2" />
							<LightningBGIcon className="l-3" />
						</div>
					</CSSTransition>
				</>, document.body)}
			</div>
		</CSSTransition>
	);
}

export default memo(Quiz);