import React from "react";

function ProfileIcon() {
	return (
		<svg className="profile-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
			<path
				fill="var(--icon-color, #fff)"
				d="M27.933 22.731a13.166 13.166 0 01-8.332 2.971 13.137 13.137 0 01-8.333-2.97C5.084 25.631.647 31.66.004 38.776-.05 39.427.46 40 1.11 40h36.968a1.11 1.11 0 001.105-1.223c-.643-7.125-5.072-13.153-11.264-16.046h.015z"
			></path>
			<path
				fill="var(--icon-color, #fff)"
				d="M19.6 23.077c6.373 0 11.54-5.166 11.54-11.539C31.14 5.166 25.974 0 19.6 0 13.229 0 8.063 5.166 8.063 11.538c0 6.373 5.166 11.539 11.539 11.539z"
			></path>
		</svg>
	);
}

export default ProfileIcon;