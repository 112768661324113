import { IPlayerStatistics } from '../redux/statistics';
import usePlayer from './usePlayer';
import useLeaderboard from './useLeaderboard';
import useStats from './useStats';

export default function useLeaderboardAroundPlayer(
	statisticName: keyof IPlayerStatistics = 'xp',
	forceUpdate = false
) {
	const { playerId, DisplayName, AvatarUrl } = usePlayer();
	const { xp } = useStats();

	const {
		completeLeaderboard,
		updateLeaderboard,
	} = useLeaderboard(statisticName);

	const playerEntry = completeLeaderboard?.find?.(e => e.PlayFabId === playerId) || {
		Position: 998,
		PlayFabId: playerId,
		Profile: {
			DisplayName,
			AvatarUrl
		},
		DisplayName,
		StatValue: xp,
	};

	return {
		updateLeaderboardAroundPlayer: () => updateLeaderboard(),
		leaderBoardAroundPlayer: [playerEntry],
	};
}
