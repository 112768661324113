import React from "react";

function MenuIcon() {
	return (
		<svg className="menu-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
			<rect width="16" height="16" fill="var(--icon-color,#FFEBC6)" rx="4"></rect>
			<rect width="16" height="16" y="24" fill="var(--icon-color,#FFEBC6)" rx="4"></rect>
			<rect width="16" height="16" x="24" fill="var(--icon-color,#FFEBC6)" rx="4"></rect>
			<rect width="16" height="16" x="24" y="24" fill="var(--icon-color,#FFEBC6)" rx="4"></rect>
		</svg>
	);
}

export default MenuIcon;
