import React from "react";

function XIcon() {
  return (
    <svg className="x-icon" xmlns="http://www.w3.org/2000/svg" fill="none" width="40" height="40" viewBox="0 0 40 40">
      <g clipPath="url(#x-icon-a)">
        <mask
          id="x-icon-b"
          width="40"
          height="40"
          x="0"
          y="0"
          maskUnits="userSpaceOnUse"
          style={{ maskType: "luminance" }}
        >
          <path fill="#fff" d="M39.12 0H0v40h39.12V0z"></path>
        </mask>
        <g mask="url(#x-icon-b)">
          <path
            fill="#FFF2D9"
            d="M23.282 16.929L37.845 0h-3.451L21.749 14.699 11.649 0H0l15.273 22.227L0 39.98h3.451l13.354-15.523L27.47 39.98h11.65L23.28 16.929h.002zm-4.727 5.494l-1.548-2.213L4.695 2.598h5.3l9.937 14.213 1.547 2.214L34.395 37.5h-5.3l-10.54-15.076z"
          ></path>
        </g>
      </g>
      <defs>
        <clipPath id="x-icon-a">
          <path fill="#fff" d="M0 0h39.12v40H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default XIcon;
