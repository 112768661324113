import React, { useCallback, useMemo, useState } from "react";
import MissionIcon from "../icons/MissionIcon";
import Lights from "../ui/Lights";
import useMissions from "../../hooks/useMissions";
import useBadges from "../../hooks/useBadges";
import ScanIcon from "../icons/ScanIcon";
import LightningBGIcon from "../icons/LightningBGIcon";
import LightningIcon from "../icons/LightningIcon";
import QRScan from "../QRScan";
import { writePlayerEvent } from "../../redux/playfab";
import { useAppDispatch, useAppSelector } from "../../redux/config/store";
import Collapsible from "../ui/Collapsible";
import { toggleCollapsedMissionSection } from "../../redux/game";
import useNativeShare from "../../hooks/useNativeShare";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import useTutorialMode from "../../hooks/useTutorialMode";
import { addNotification, notificationGenerator } from "../../redux/notifications";

function Mission({ mission, actions }: { mission:IXRMissionItem, actions: Record<string, () => void>}) {
	const { text, raw } = useTexts();
	const lang = useAppSelector((state) => state.env.Lang);
	const isCompleted = mission?.PlayerStatus?.IsComplete;
	const hasThreshold = Boolean(mission?.objectives?.[0]?.PlayerStatus?.Trackers[0]?.Threshold > 1);
	const hasMultipleObjectives = mission?.objectives?.length > 1;

	const multipleObjectivesThreshold = (mission.objectives as IXRMissionObjectiveParsedData[]).reduce((carry, obj) => {
		return carry + (obj.PlayerStatus?.IsComplete ? 1 : 0);
	}, 0);
	
	const xpReward = mission.rewards.find(r => r.dataType === 'stat_change' && r.dataKey === 'xp');

	const onClick = useCallback(() => {
		const action = (mission.data.action as string) || 'none';
		if (isCompleted) return;

		actions[action]?.();
	}, [actions, isCompleted, mission.data.action]);

	const { catalog } = useBadges();
	const badgeReward = mission.rewards.find(r => r.dataType === 'item_grant' && catalog.find(b => b.itemId === r.dataVal));

	const displayName = mission.data?.localization?.[lang]?.displayName || mission.playfab.DisplayName;
	const description = mission.data?.localization?.[lang]?.description || mission.data.description;

	const tutorialHighlight = mission.data?.tutorialHighlight === 1;

	return (
		<div className={`box mission ${isCompleted ? 'accent' : ''} ${tutorialHighlight ? 'tutorial-show' : ''}`} onClick={onClick}>
			<div className="checkbox box sub-box">
				<LightningIcon />
			</div>
			<div className="content">
				<div className="name">{displayName}</div>
				<div className="description">
					{hasThreshold && <span className="threshold">{mission?.objectives[0].PlayerStatus?.Trackers[0]?.Value}/{mission?.objectives[0].PlayerStatus?.Trackers[0]?.Threshold} </span>}
					{hasMultipleObjectives && <span className="threshold">{multipleObjectivesThreshold}/{mission?.objectives.length} </span>}
					<span {...raw(description)} />
				</div>
			</div>
			<div className="rewards">
				{xpReward && <div className="reward">+{xpReward.dataVal} <span {...raw(text?.missions?.xp)} /></div>}
				{badgeReward && <div className="reward" {...raw(text?.missions?.badge)} />}
				{mission.data?.additionalRewardText && <div className="reward extra">{mission.data?.localization?.[lang]?.additionalRewardText || mission.data.additionalRewardText as string}</div>}
			</div>
		</div>
	);
}

const STRING_TO_CHECK = 'mission-';

function getTag(m:IXRMissionItem) {
	const tag = m.playfab?.Tags?.find?.(t => t.startsWith('order:'));
	if (tag) return parseInt(tag.replace('order:', ''));
	return 1;
}

export default function Missions() {
	const { text, raw } = useTexts();
	const rawMissions = useMissions();
	const dispatch = useAppDispatch();
	const [qrModalVisible, setQRModalVisible] = useState(false);

	const [scannedCodes, setScannedCodes] = useState<string[]>(JSON.parse(localStorage.getItem('scannedCodes') || '[]'));

	const missions = useMemo(() => {
		return [...rawMissions].sort((a, b) => {
			const tagA = getTag(a);
			const tagB = getTag(b);
			if (tagA < tagB) return -1;
			if (tagA > tagB) return 1;
			return 0;
		});
	}, [rawMissions]);

	const { isTutorialMode } = useTutorialMode();

	const collapsedMissionSections = useAppSelector((state) => state.game.collapsedMissionSections);

	const sections = missions.reduce((acc, mission) => {
		if (!acc[mission.type.title]) {
			acc[mission.type.title] = [];
		}

		acc[mission.type.title].push(mission);

		return acc;
	}, {} as Record<string, IXRMissionItem[]>);

	const onScan = useCallback((code:string) => {
		if (scannedCodes.includes(code)) {
			dispatch(addNotification(notificationGenerator({
				title: text.qrscan.alreadyScannedTitle,
				message: text.qrscan.alreadyScannedText,
			})));

			return true;
		} else if (code.includes(STRING_TO_CHECK)) {
			dispatch(writePlayerEvent({
				name: 'player_visited_booth',
				body: {
					boothName: code,
				}
			})).then(() => {
				setScannedCodes([...scannedCodes, code]);
				localStorage.setItem('scannedCodes', JSON.stringify([...scannedCodes, code]));
			});
			setQRModalVisible(false);
			return true;
		}
		return false;
	}, [dispatch, scannedCodes, text?.qrscan?.alreadyScannedText, text?.qrscan?.alreadyScannedTitle]);

	const { canShare, share } = useNativeShare(window.location.origin);
	const navigate = useNavigate();

	const actions = {
		'none': () => {},
		'qr-scan': () => setQRModalVisible(true),
		'share': canShare ? share : () => navigate(ROUTES.ROOT + ROUTES.MENU),
	};

	return (
		<CSSTransition in={missions.length > 0} appear={true} timeout={2000} mountOnEnter unmountOnExit>
			<div className={`page missions ${isTutorialMode ? 'tutorial' : ''}`}>
				<LightningBGIcon />
				<div className="box sub-box ctn">
					<div className="qr-scan" onClick={() => setQRModalVisible(true)}>
						<div className="move box">
							<div className="qr-code">
								<ScanIcon />
							</div>
							<div className="label" {...raw(text?.missions?.scan)} />
						</div>
					</div>
					<div className="top box sub-box">
						<MissionIcon />
						<div className="title" {...raw(text?.missions?.missions)} />
						<Lights />
					</div>
					<div className="sections">
						{isTutorialMode && (
							<div className="tutorial-text">
								<svg xmlns="http://www.w3.org/2000/svg" fill="none" className="arrow" viewBox="0 0 47 129">
									<path
										fill="#fff"
										d="M8.707.293a1 1 0 00-1.414 0L.929 6.657A1 1 0 002.343 8.07L8 2.414l5.657 5.657a1 1 0 101.414-1.414L8.707.293zM9 128V1H7v127h2z"
									></path>
									<path stroke="#fff" strokeWidth="2" d="M7 128h40"></path>
								</svg>
								<div className="text" {...raw(text?.missions?.tutorial)} />
							</div>
						)}
						{Object.entries(sections).map(([section, sectionMissions]) => {
							const name = text?.missionClasses?.[section] || section;
							let description = null;
							if (section.toLowerCase().includes('floor')) {
								description = (
									<div className="section-description" {...raw(text?.missions?.scanDescription)} />
								);
							}

							return (
								<Collapsible
									isOpened={!collapsedMissionSections.includes(section)}
									toggleCollapse={() => dispatch(toggleCollapsedMissionSection(section))}
									heading={name + ' (' + sectionMissions.length + ')'}
									key={section}
									className="section"
									headingClassName="section-title"
								>
									<div className="section-missions">
										{description}
										{sectionMissions.map(m => <Mission key={m.itemId} mission={m} actions={actions} />)}
									</div>
								</Collapsible>
							);
						})}
						{Object.values(sections).length === 0 && (
							<>
								<div className="section empty">
									<div className="section-title">&nbsp;</div>
									<div className="section-missions">
										<div className="box mission"></div>
										<div className="box mission"></div>
										<div className="box mission"></div>
									</div>
								</div>
								<div className="section empty">
									<div className="section-title">&nbsp;</div>
									<div className="section-missions">
										<div className="box mission"></div>
										<div className="box mission"></div>
									</div>
								</div>
								<div className="section empty">
									<div className="section-title">&nbsp;</div>
									<div className="section-missions">
										<div className="box mission"></div>
										<div className="box mission"></div>
										<div className="box mission"></div>
									</div>
								</div>
							</>
						)}
					</div>
				</div>
				{isTutorialMode && <div className="overlay"></div>}
				{qrModalVisible && <QRScan onChange={onScan} onClose={() => setQRModalVisible(false)} />}
			</div>
		</CSSTransition>
	);
}