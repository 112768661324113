import React from "react";

function LightningBGIcon({ className = '' }:{ className?: string }) {
	return (
		<svg className={`lightning-bg-icon ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 1227 1444">
			<path fill="var(--background-color, #fff)" d="M640.909 0v1012.33H30L640.909 0z"></path>
			<path fill="var(--background-color, #fff)" d="M616.091 1444V431.672H1227L616.091 1444z"></path>
			<path fill="var(--foreground-color, #FF4E02)" d="M610.909 0v1012.33H0L610.909 0z"></path>
			<path fill="var(--foreground-color, #FF4E02)" d="M586.091 1444V431.672H1197L586.091 1444z"></path>
		</svg>
	);
}

export default LightningBGIcon;