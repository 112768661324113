import { createSlice } from '@reduxjs/toolkit';
import { getXrApi } from '../api/apiBridge';
import createDebouncedAsyncAction from './async/createDebouncedAsyncAction';
import xrAction from './async/xrAction';
import { IPlayerStatistics } from './statistics';
import { IRootState } from './config/store';

export const getLeaderboard = createDebouncedAsyncAction(
	'blob-cache/getLeaderboard',
	async ({ StatisticName, url }: { StatisticName:string, url:string }) => {
		console.log(url);
		const response = await fetch(url);
		const data = await response.json();
		return data;
	}
);

const leaderboard = createSlice({
	name: 'leaderboard',
	initialState: {} as Record<keyof IPlayerStatistics, LeaderboardEntry[]>,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getLeaderboard.actions.fulfilled, (state, action) => {
			state[action.meta.arg.StatisticName] = action.payload || [];
		});
	},
});

export default leaderboard;