import { DISPLAY_NAME_DIGITS } from '../Constants';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import { updateDisplayName } from '../redux/playfab';

const trimNumber = (str:string) => {
	return str?.length ? str.slice(0, str.length - DISPLAY_NAME_DIGITS) : '';
};

export default function usePlayer() {
	const {
		PlayFabId,
		DisplayName,
		AvatarUrl,
		currencies,
		SessionTicket,
	} = useAppSelector((state) => state.playfab);
	
	const dispatch = useAppDispatch();

	return {
		playerId: PlayFabId,
		currencies,
		DisplayName: trimNumber(DisplayName),
		AvatarUrl,
		SessionTicket,
		updateDisplayName: (displayName:string) => dispatch(updateDisplayName(displayName)),
	};
}