import React, { useEffect, useState } from "react";
import LightningBGIcon from "../icons/LightningBGIcon";
import Avatar from "../ui/Avatar";
import usePlayerData from "../../hooks/usePlayerData";
import ProgressionTab from "./profile/ProgressionTab";
import BadgesTab from "./profile/BadgesTab";
import useBadges from "../../hooks/useBadges";
import StatisticsTab from "./profile/StatisticsTab";
import useTexts from "../../hooks/useTexts";
import { CSSTransition } from "react-transition-group";
import useTutorialMode from "../../hooks/useTutorialMode";
import usePlayer from "../../hooks/usePlayer";

export default function Profile() {

	
	const {
		DisplayName,
	} = usePlayer();

	const { text, raw } = useTexts();

	const {
		ownedBadges,
		totalBadges,
	} = useBadges();

	// make it a dictionary
	const tabs = {
		'progress': {
			tab: <ProgressionTab />,
			label: <><strong {...raw(text?.profile?.tabs?.progress)} /></>,
		},
		'badges': {
			tab: <BadgesTab />,
			label: <><strong><span {...raw(text?.profile?.tabs?.badges)} /> - {ownedBadges}</strong> / {totalBadges}</>,
		},
		'statistics': {
			tab: <StatisticsTab />,
			label: <><strong {...raw(text?.profile?.tabs?.statistics)} /></>,
		},
	};

	const [currentTab, setCurrentTab] = useState('progress');
	const [showTabs, setShowTabs] = useState(false);

	useEffect(() => {
		//animation delay for tabs
		const st = setTimeout(() => {
			setShowTabs(true);
		}, 600);

		return () => {
			st && clearTimeout(st);
		}
	}, []);

	const { isTutorialMode } = useTutorialMode();
	
	return (
		<CSSTransition in={true} appear={true} timeout={1200} mountOnEnter unmountOnExit>
			<div className={`page profile ${isTutorialMode ? 'tutorial' : ''}`}>
				<LightningBGIcon />
				<div className="top">
					<Avatar />
					<div className="display-name">{DisplayName}</div>
					<div className="company">Alice & Smith</div>
				</div>

				<div className="tab-btns">
					{Object.keys(tabs).map((key) => {
						const tab = tabs[key];
						const state = currentTab === key ? 'accent' : 'secondary';
						return <div key={key} className={'box tab-btn ' + state} onClick={() => currentTab !== key && setCurrentTab(key)}>{tab.label}</div>;
					})}
				</div>

				<div className="tabs">
					{showTabs && tabs[currentTab].tab}
				</div>
			</div>
		</CSSTransition>
	);
}