/* eslint-disable import/no-anonymous-default-export */
export default {
	"menu": {
		"profile": "Profile",
		"missions": "Missions",
		"leaderboard": "Leaderboard",
		"menu": "Menu",
		"buzzr": {
			"title": "Hands on<br/>the BUZZR®!",
			"text": "Tap below when asked<br />to play along on the big<br />screen."
		}
	},
	"qrscan": {
		"close": "Close",
		"selectText": "Change Camera",
		"help": "Point your camera at the QR code and tap the capture button once the code is highlighted below.<br /><br />Make sure to select the correct camera using the dropdown below.<br/><br/>Make sure not to get too close to the QR code.",
		"alreadyScannedTitle": "QR Code already scanned",
		"alreadyScannedText": "You have already scanned this QR code",
		"invalidQR": "Invalid QR Code",
		"noCamera": "Can't access camera<br />Tap here to try again.<br />Make sure your browser has permission to access the camera."
	},
	"profile": {
		"tabs": {
			"progress": "Progress",
			"badges": "Badges",
			"statistics": "All Statistics"
		},
		"progression": {
			"title": "Progression",
			"triviaCompleted": "Trivia<br/>Completed",
			"fastestAnswer": "Fastest<br/>Answer (sec)",
			"badgesUnlocked": "Badges<br/>Unlocked",
			"boothsVisited": "Booths<br/>Visited",
			"leaderboardPosition": "Leaderboard<br/>Position",
			"objective": "Reach level 3 for a chance to<br/>win an Xbox Series X!",
			"toLevelUp": "to level up"
		},
		"statistics": {
			"partnerBoothsVisited": "Partner Booths Visited",
			"badgesUnlocked": "Badges Unlocked",
			"fastestTriviaAnswer": "Fastest Trivia Answer (sec)",
			"triviaCompleted": "Trivia Completed",
			"pollsCompleted": "Polls Completed",
			"missionsCompleted": "Missions Completed",
			"leaderboardPosition": "Leaderboard Position"
		},
		"tutorial": "Reach level 3 to enter our raffle for a chance to win  an Xbox Series X!"
	},
	"ftue": {
		"description": "Play along with your favorite<br/ >BUZZR® retro game shows from the comfort of your own device.<br/ >Real challenges. Real prizes.<br/ >All in real-time.",
		"createProfile": "Create your<br/>user profile",
		"scanQR": "Tap here to<br/>start the app",
		"readyTitle": "Ready to<br/>Play!",
		"readyText": "You have successfully created your account and are ready to play at Beat the BUZZR®!",
		"startNow": "Start now",
		"errorTitle": "Oops!",
		"errorText": "There was an error creating your account. Please try again.",
		"retry": "Retry"
	},
	"menuPage": {
		"shareExperience": "Share your experience",
		"upNext": "Up Next!",
		"partners": "Partners",
		"language": "English",
		"termsConditions": "Terms & Conditions"
	},
	"partners": {
		"back": "Back",
		"microsoft": {
			"title": "",
			"description": "Microsoft empowers customers to reimagine media with data & AI, fostering creative storytelling and personalized experiences while enhancing content delivery, monetization, and audience engagement through intelligent cloud and edge solutions.",
			"link": "https://www.microsoft.com/en-us/industry/media-entertainment",
			"linkLabel": "Contact Us"
		},
		"fremantle": {
			"title": "",
			"description": "Fremantle is a leading global creator and distributor of some of the world’s most successful content across all genres. Fremantle produces IP across the 27 territories it operates in. From America’s Got Talent to American Idol, Password to The Price Is Right, Fellow Travelers to Poor Things, Fremantle creates and delivers irresistible entertainment.",
			"link": "https://fremantle.com/companies-and-labels",
			"linkLabel": "Contact Us"
		},
		"nvidia": {
			"title": "",
			"description": "For decades, NVIDIA has helped the Media & Entertainment industry create and deliver award-winning content, and now it’s redefining the future of M&E with state-of-the-art AI, hardware, software platforms, and networking solutions.",
			"link": "https://www.nvidia.com/en-us/industries/media-and-entertainment/",
			"linkLabel": "Contact Us"
		},
		"aliceandsmith": {
			"title": "Alice & Smith",
			"description": "Alice & Smith is a full-stack entertainment agency specializing in user engagement via XR Server, a LiveOps engine that uses sub-second processing and gamification features to create real-time, personalized, progression-based user experiences.",
			"link": "https://www.xrserver.com/ ",
			"linkLabel": "Contact Us"
		},
		"primefocustechnologies": {
			"title": "Prime Focus Technologies",
			"description": "Prime Focus Technologies (PFT) is the creator of CLEAR® and CLEAR®AI. It offers streaming platforms, studios, and broadcasters transformational AI-led technology and media services powered by the cloud that help them lower their Total Cost of Operations (TCOP) by automating their content supply chain.<br /><br />PFT works with major companies like Walt Disney-owned Star TV, Channel 4, ITV, Sinclair Broadcast Group, A&E Networks, Warner Media, PBS, CBS Television Studios, 20th Century Fox Television Studios, Lionsgate, Showtime, HBO, NBCU, TERN International, Disney+ Hotstar, BCCI, Indian Premier League and more.",
			"link": "https://www.primefocustechnologies.com/contact-us",
			"linkLabel": "Contact Us"
		},
		"cinnafilm": {
			"title": "Cinnafilm",
			"description": "Cinnafilm, Inc. is a global leader in innovative video and audio optimization solutions for film studios, broadcasters, OTT streamers, and web delivery. Available in the cloud and on-prem, Cinnafilm’s patented software applications are industry-leading solutions in the demanding world of professional-quality media transformations and live broadcast conversions. ",
			"link": "https://cinnafilm.com/contact/",
			"linkLabel": "Contact Us"
		},
		"mediakind": {
			"title": "MediaKind",
			"description": "MediaKind revolutionizes streaming and live events, integrating cutting-edge video technology with personalization and advanced ad-tech.<br /><br />We enable global broadcasters and content providers to deliver unparalleled viewer experiences, offering scalable solutions for live and on-demand content on any platform. With over 30 years of industry leadership, our technology supports personalized, efficient delivery, enhancing engagement and monetization in both on-prem and cloud environments",
			"link": "https://get.mediakind.com/nab-2024",
			"linkLabel": "Contact Us"
		},
		"microsoftadvertising": {
			"title": "Microsoft Advertising",
			"description": "Microsoft Advertising offers scalable technology solutions, spanning search, native, display, video, and retail media, to reach consumers across all aspects of their digital lives. Connect with more than a billion people using Microsoft experiences that have a proven track record of higher buying power and online spending or reach any audience across the open web. We operate one of the world’s largest global marketplaces and are using our data-driven platforms, audience intelligence, and AI-capabilities to transform the industry. We are committed to making the web work for everyone – consumers, advertisers, publishers, and platforms alike. Our end-to-end solutions and globally scaled advertising business serves partner properties, and buyers and sellers of media to help them deliver business results.",
			"link": "https://about.ads.microsoft.com/en-us",
			"linkLabel": "Contact Us"
		},
		"symphonyai": {
			"title": "SymphonyAI",
			"description": "SymphonyAI is building the leading enterprise AI SaaS company for digital transformation across the most critical and resilient growth verticals, including media, retail, consumer packaged goods, finance, manufacturing, and IT service, with many leading enterprise clients. Founded in 2017, SymphonyAI has grown rapidly to 3,000 talented leaders, data scientists and other professionals. ",
			"link": "https://symphonyai.com/media/get-started/",
			"linkLabel": "Contact Us"
		}
	},
	"lang": {
		"back": "Back",
		"audio": "Audio",
		"application": "Application",
		"english": "English",
		"german": "Deutsch",
		"french": "Français",
		"spanish": "Español"
	},
	"termsConditions": {
		"back": "Back"
	},
	"missions": {
		"scan": "Scan",
		"missions": "Missions",
		"xp": "XP",
		"badge": "Badge",
		"tutorial": "Complete missions for even more rewards",
		"scanDescription": "Tap the corresponding mission to scan the QR code. View the <a href='https://nab24.mapyourshow.com/8_0/floorplan/' target='_blank'>NAB map here</a>",
	},
	"rankings": {
		"leaderboard": "Leaderboard",
		"description": "Rank in the top 20 to be eligible<br />for a free Xbox Game Pass!<br/><small>Leaderboard refreshes every 5 mins</small>",
		"xp": "XP"
	},
	"quiz": {
		"questionBrought":"Question brought to you by ",
		"submit": "Submit",
		"close": "Close",
		"notificationTitle": "You earned<br />%XP% XP",
		"notificationTextTrivia": "Good answer",
		"notificationTextSurvey": "Thanks for participating"
	},
	"quizTutorial": {
		"title": "How to play",
		"text": "Watch BUZZR®, the retro game show channel, on the big screen.<br /><br />Look out for notifications to play along on your device. The more you play along, the more XP you’ll earn!",
		"btnLabel": "Ok"
	},
	"noQuiz": {
		"buzzr": "BUZZR® BEATR<br/>Incoming!",
		"noBuzzr": "No active BUZZR® BEATR",
		"comeBack": "Keep watching the show!"
	},
	"missionClasses": {
		"Tutorial": "Tutorial",
		"On Screen": "On Screen",
		"On the floor / West Hall": "On the floor / West Hall",
		"On the floor / Multiple Locations": "On the floor / Multiple Locations",
		"On the floor / South Lower Hall": "On the floor / South Lower Hall"
	},
	"notifications": {
		"mission": "You have gained<br />%XP% %BADGE%",
		"badge": "+ Badge",
		"xp": "XP"
	}
};