import useAnimationFrame from "../../hooks/useAnimationFrame"
import React, { useRef, useState } from "react";
import { constrain } from "../../utils/constrain";
import CircleProgress from "./CircleProgress";

type CountdownProps = {
	targetTime: number,
	startTime: number,
	reverse?: boolean,
	onRunningChange?: (isRunning:boolean) => void,
};

export default function Countdown({ targetTime, startTime, reverse = false, onRunningChange }:CountdownProps) {
	const counter = useRef<HTMLDivElement>(null);
	const [isRunning, setIsRunning] = useState(false);
	const [percent, setPercent] = useState(0);
	const totalTime = targetTime - startTime;

	useAnimationFrame(() => {
		const t = targetTime - (Date.now() / 1000);
		const time = constrain(t, 0, Math.abs(totalTime));

		if (time <= 0 && isRunning) {
			setIsRunning(false);
			onRunningChange?.(false);
		} else if (time > 0 && !isRunning) {
			setIsRunning(true);
			onRunningChange?.(true);
		}

		if (counter.current) {
			counter.current!.innerText = Math.ceil(time).toString();
			const percent = reverse ? constrain(time / totalTime, 0, 1) : 1 - constrain(time / totalTime, 0, 1);
			setPercent(percent);
		}
	});

	return (
		<div className="countdown">
			<CircleProgress progress={percent} />
			<div className="num" ref={counter}>5</div>
		</div>
	)
}