import { getCatalog } from '../redux/catalog';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/config/store';
import usePlayer from './usePlayer';

export default function useCatalog() {
	const {
		items,
	} = useAppSelector((state) => state.catalog);

	const {
		playerId,
	} = usePlayer();

	const dispatch = useAppDispatch();

	useEffect(() => {
		if (playerId && items.length === 0) {
			dispatch(getCatalog());
		}
	}, [dispatch, items.length, playerId]);

	return {
		items: items || [],
	};
}