import React, { memo, useEffect, useState } from "react";

function Lights({ count = 11 }) {
	const [isOdd, setIsOdd] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			setIsOdd(v => !v);
		}, 1000);

		return () => clearInterval(interval);
	}, [setIsOdd]);

	return (
		<div className="lights">
			{[...Array(11)].map((_, i) => <div key={i} className={`light ${Boolean((i + 1) % 2) === isOdd ? 'active' : ''}`} />)}
		</div>
	);
}

export default memo(Lights);