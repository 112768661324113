import React from "react";

function CloverIcon() {
	return (
		<svg className="clover-icon" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 37 41">
			<path
				fill="#fff"
				d="M23.14 18.486c32.31-.04-4.718-36.988-4.678-4.661-.04-32.327-36.968 4.72-4.659 4.68-27.63.04-4.558 27.049 2.688 14.62v7.29h3.981v-7.35c7.207 12.568 30.359-14.6 2.688-14.56l-.02-.02zm-4.718 5.975c0-.438.04-.837.04-1.315 0 .458.04.877.06 1.315h-.1z"
			></path>
		</svg>
	);
}

export default CloverIcon;