import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRootState, useAppDispatch } from '../redux/config/store';
import { getMissionInventory } from '../redux/missions';
import usePlayer from './usePlayer';

export default function useMissions(filter:string = null) {
	const missions = useSelector((state:IRootState) => state.missions.list);
	const { playerId } = usePlayer();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (missions.length === 0 && playerId) {
			dispatch(getMissionInventory());
		}
	}, [dispatch, missions, playerId]);

	const filteredMissions = useMemo(() => {
		return missions.filter(m => Boolean(m.PlayerStatus));
	}, [missions]);

	if (!filter) return filteredMissions;
	return filteredMissions.filter(mission => mission.type.title === filter);
}