import { PayloadAction, createSelector } from "@reduxjs/toolkit";
import { IRootState, useAppDispatch, useAppSelector } from "../redux/config/store";
import { AFAnswerQuizResponse, answerQuiz, getQuizStatistics, removeQuizInstance } from "../redux/quizzes";
import { updatePlayerData } from "../redux/playerData";
import { useCallback, useEffect, useState } from "react";

const quizzesSelectorRaw = (state: IRootState) => state.quizzes.instances;
const statisticsSelectorRaw = (state: IRootState) => state.quizzes.statistics;
export const currentQuizSelector = createSelector([
	quizzesSelectorRaw,
], (quizzes) => {
	return quizzes.find((quiz) => {
		return (quiz.timestamp * 1000) + (quiz.totalExpiration * 1000) > Date.now();
	});
});

const currentStatisticsSelector = createSelector([
	currentQuizSelector,
	statisticsSelectorRaw,
], (currentQuiz, statistics) => {
	return statistics.find(s => currentQuiz?.customData?.groupId === s.id) || { id: currentQuiz?.customData?.groupId, result: [] as XRQuizChoiceStatistics[] };
});

export default function useQuizzes() {
	const dispatch = useAppDispatch();
	const currentQuiz = useAppSelector(currentQuizSelector);
	const statistics = useAppSelector(currentStatisticsSelector);
	const previousAnswer = useAppSelector((state) => state.quizzes.answers[currentQuiz?.customData?.groupId]);
	const [fetchingAnswer, setFetchingAnswer] = useState(false);

	const getStats = useCallback(() => {
		return dispatch(getQuizStatistics({
			questionId: currentQuiz?.questions?.[0]?.id,
			groupId: currentQuiz.customData?.groupId || '',
			InstanceId: currentQuiz.id,
		}))
	}, [currentQuiz, dispatch]);

	useEffect(() => {
		if (currentQuiz && !currentQuiz.isAnswered && previousAnswer && !fetchingAnswer) {
			getStats();
		}
	}, [currentQuiz, dispatch, getStats, previousAnswer, fetchingAnswer]);

	return {
		hasQuiz: Boolean(currentQuiz),
		currentQuiz,
		answerQuiz: (answer: string) => {
			setFetchingAnswer(true);

			const isExpiredSurvey = currentQuiz.type === 'survey' && answer === 'timeout';
			if (!isExpiredSurvey) {
				dispatch(updatePlayerData({
					[`quiz-${currentQuiz?.customData?.groupId}`]: answer,
				}));
			}

			if (answer === 'timeout') {
				return getStats();
			}

			return (dispatch(answerQuiz({
				Answers: { [currentQuiz?.questions?.[0]?.id]: answer },
				groupId: currentQuiz.customData?.groupId || '',
				InstanceId: currentQuiz.id,
				type: currentQuiz.type,
				TimeToAnswer: Date.now() - (currentQuiz.timestamp * 1000),
			})) as Promise<PayloadAction<AFAnswerQuizResponse>>).then((resp) => {
				setFetchingAnswer(false);
				return resp;
			});
		},
		getQuizStatistics: getStats,
		statistics,
		previousAnswer,
		closeQuiz: () => dispatch(removeQuizInstance(currentQuiz?.instanceId)),
	}
}