import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch, IRootState } from '../redux/config/store';
import { getOtherPlayerProfile } from '../redux/otherPlayers';

export function useOtherPlayerProfile(playFabId:string, forceRefresh = false) {
	const dispatch = useAppDispatch();

	const otherPlayerProfile = useSelector((state:IRootState) => state.otherPlayers.profiles[playFabId]);

	useEffect(() => {
		if (!otherPlayerProfile && playFabId) {
			dispatch(getOtherPlayerProfile(playFabId));
		}
	}, [dispatch, otherPlayerProfile, playFabId]);

	useEffect(() => {
		if (forceRefresh) {
			dispatch(getOtherPlayerProfile(playFabId));
		}
	}, [dispatch, forceRefresh, playFabId]);
	
	return otherPlayerProfile;
}
