import { useMemo } from "react";
import { ITEM_CLASSES } from "../Constants";
import useCatalog from "./useCatalog";
import useInventory from "./useInventory";

const classes = [ITEM_CLASSES.BADGE];

export default function useBadges() {
	const raw = useCatalog().items;
	const catalog = useMemo(() => {
		return raw.filter((item) => item.playfab.ItemClass === ITEM_CLASSES.BADGE);
	}, [raw]);
	const badges = useInventory(classes);

	return {
		catalog,
		badges,
		totalBadges: catalog.length,
		ownedBadges: badges.length,
	}
}