import { getXrApi } from '../api/apiBridge';
import { saveUniqueIdentifier } from '../components/routes/Login';

export function useLogout() {
	function logout() {
		saveUniqueIdentifier(true);
		localStorage.setItem('lang', 'en');
		getXrApi().Auth.Logout().then(() => window.location.reload());
	}

	return {
		logout,
	};
}