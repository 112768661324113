import React from "react";

function LinkedInIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" fill="none" width="40" height="40" viewBox="0 0 40 40">
			<g fill="#FFF2D9" clipPath="url(linked-in-a)">
				<path d="M9.311 12.868h-8.03a.645.645 0 00-.645.645v25.798c0 .356.289.645.645.645h8.03a.645.645 0 00.645-.645V13.513a.645.645 0 00-.645-.645zM5.299.044A5.302 5.302 0 000 5.337a5.303 5.303 0 005.299 5.295 5.3 5.3 0 005.294-5.295A5.3 5.3 0 005.3.044zm24.437 12.183c-3.225 0-5.61 1.387-7.055 2.962v-1.675a.645.645 0 00-.646-.646h-7.69a.645.645 0 00-.645.646V39.31c0 .356.289.645.645.645h8.013a.645.645 0 00.645-.645V26.547c0-4.301 1.168-5.977 4.166-5.977 3.266 0 3.525 2.687 3.525 6.198v12.543c0 .356.289.645.645.645h8.016A.645.645 0 0040 39.31V25.16c0-6.396-1.22-12.934-10.264-12.934z"></path>
			</g>
			<defs>
				<clipPath id="linked-in-a">
					<path fill="#fff" d="M0 0h40v40H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default LinkedInIcon;
